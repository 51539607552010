<template>
  <div>
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>商贸成单审批</span>
      </div>

    </div>
    <!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
        @keyWordMethod="keyWordMethod"
        @highKeyWordMethod="highKeyWordMethod"
        @department_idChange="department_idChange"
    />

    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @detail="detail"
    />



  </div>
</template>
<script>
import {businessEndAuditList,allNormalUserName} from "../../../api/module/business/business_func";
import {oneDepartment, twoDepartment} from "api/user/user_func";
export default {
  components: {},
  props: [],
  data() {
    return {

      audit_status:0,
      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: true,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [ {
          name: '发起人',
          field: 'user_id',
          type: 2,
        },{
          name: '交易负责人',
          field: 'transaction_loser',
          type: 2,
        },{
          name: '供应商',
          field: 'supplier',
          type: 1,
        },{
          name: '采购商',
          field: 'purchaser',
          type: 1,
        },{
          name: '发起时间',
          field: 'start_time',
          type: 4,
        },{
          name: '结束时间',
          field: 'end_time',
          type: 4,
        },],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        department_idOptions: [],
        user_idOptions: [],
        transaction_loserOptions: [],

        //高级搜索的字段
        heighKewWordField: {department_id: '', user_id: '', transaction_loser: '', supplier: '', purchaser: '',start_time: '',end_time:''},
        //tabs 标题
        //tabs 标题
        tabsData: [{
          label: '待审核',
          name: '0',
          total: 0,
        },{
          label: '审核通过',
          name: '1',
          total: 0,
        },{
          label: '审核不通过',
          name: '2',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '订单编号',
          field: 'id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '发起部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '发起人',
          field: 'user_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '交易负责人',
          field: 'transaction_loser',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '供应商',
          field: 'supplier',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '采购商',
          field: 'purchaser',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '发起时间',
          field: 'created_at',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        },

        //   {
        //   title: '订单状态',
        //   field: 'create_audit_status_name',
        //   isWidth: false,
        //   width: '120px',
        //   headerAlign: 'center',
        //   align: 'center',
        //   tooltip: false,
        //   type: 0,
        // }, {
        //   title: '审核状态',
        //   field: 'end_audit_status_name',
        //   isWidth: false,
        //   width: '120px',
        //   headerAlign: 'center',
        //   align: 'center',
        //   tooltip: false,
        //   type: 0,
        // },

          {
          title: '操作',
          isWidth: false,
          width: '220px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '查看',
            type: 'primary',
            method: 'detail',
            status: '20000',
            key:'detail',
            id: 20000,
          }],
        }],
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        status: "1",//1通过2未通过
        consent_information_json: {
          dev_red_rate:0,
          dev_grow_value:0,
          fail_reasons:'',//审批意见
        },//通过传这个
        id:0
      },
      formLabelWidth:'120px',
      //表格数据
      tableData: [],
      //addDialog提交数据
      subData: {}
    };
  },
  created() {
    this.getDepartmentList()

    allNormalUserName({}).then((result) => {
      let data = result.data;
      let data2 = JSON.parse(JSON.stringify(data));
      for (let i = 0; i < data.length; i++){
        data[i].value = data[i].id
        data[i].label = data[i].name
      }
      for (let i = 0; i < data2.length; i++){
        data2[i].value = data2[i].name
        data2[i].label = data2[i].name
      }

      this.tabsProp.user_idOptions = data

      this.tabsProp.transaction_loserOptions = data2

    })


    this.initLoad();

  },
  mounted() {
  },
  methods: {

    getDepartmentList(){
      //获取部门信息
      oneDepartment({}).then(res=>{
        let list = res.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].department_name;
          list[i].value = list[i].id ;
        }
        this.tabsProp.department_idOptions = list
      })
    },


    department_idChange(val){
      let data = {
        pid : val
      }
      if (val!=''){
        twoDepartment(data).then(res=>{
          let list = res.data;
          for (let i = 0; i < list.length; i++) {
            list[i].label = list[i].department_name;
            list[i].value = list[i].id;
          }
          this.tabsProp.station_idOptions = list
        })
      }
    },



    businessStatisticsExcel(){
      let param = JSON.parse(JSON.stringify(this.tabsProp.heighKewWordField))
      let data = `?department_id=`+param.department_id
          +`&user_id=`+param.user_id
          +`&start_time=`+this.formatDate(param.start_time/1000)
          +`&end_time=`+this.formatDate(param.end_time/1000)
      console.log(this.$route)
      console.log(data)
      console.log(window.location.host)

      window.location.href = "http://"+window.location.host+"/api/business/businessStatisticsExcel"+data;
      // let data = {
      //   department_id:param.department_id,
      //   user_id:param.user_id,
      //   start_time:this.formatDate(param.start_time/1000),
      //   end_time:this.formatDate(param.end_time/1000)
      // }
      // console.log(data)
      //
      // businessStatisticsExcel(data).then((result) => {
      //
      //     this.$message({
      //       message: result.msg,
      //       type: 'success'
      //     });
      // })
    },
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page:this.tableProp.page.current_page,
        status:this.tabsProp.active,
      }
      console.log(params)
      businessEndAuditList(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        let end_audit_status_arr = ["待审核","审核通过","审核不通过"]
        let create_audit_status_arr = ["待审核","审核通过","审核不通过"]
        for (let i = 0; i <  res.data.length; i++) {
          res.data[i].end_audit_status_name = end_audit_status_arr[res.data[i].end_audit_status]
          res.data[i].create_audit_status_name = create_audit_status_arr[res.data[i].create_audit_status]
          res.data[i].user_name=res.data[i].username

        }
        this.tableData = res.data
        console.log(this.tableData)
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;

      this.initLoad()
      console.log(tab.name)
    },

    //table 详情 按钮点击
    detileApproval(row, index) {
      console.log(row)
      console.log(index)
      this.$router.push({path:'/business_management/business_detail', query:{id:row.id}})

    },
    //table 确认成单 按钮点击
    passOrder(row, index) {
      console.log(index)

      this.dialogFormVisible  = true;

      this.form.id = row.audit_info.id
      this.form.consent_information_json.dev_grow_value = row.dev_grow_value
      this.form.consent_information_json.dev_red_rate = row.dev_red_rate
      // this.form.consent_information_json.fail_reasons = row.audit_msg
      this.form.consent_information_json.fail_reasons = ''

    },
    //table 确认成单 按钮点击
    edit(row, index) {
      console.log(index)
      this.$router.push({path:"./add?id="+row.id})

    },
    detail(row, index) {
      console.log(index)
      this.$router.push({path:"./business_approval/chengdan_details?id="+row.id+"&audit_id="+row.audit_id+"&status="+row.status})

    },
    order(row, index) {
      console.log(index)
      this.$router.push({path:"./order?id="+row.id})

    },


    addLog() {
      this.$router.push({path:'./add'})
    },



    //table 分页 点击当前页
    pageCurrentChange(val) {


      this.highKeyWordMethod(val)

    },
    // addDialog 点击确定
    addDialogSub() {
      let data = {...this.form}
      // data.consent_information_json.dev_red_rate=data.consent_information_json.dev_red_rate/100
      data.consent_information_json = JSON.stringify(data.consent_information_json)
      // auditStep1(data).then((result) => {
      //   this.dialogFormVisible = false;
      //   this.$message({
      //     message: result.msg,
      //     type: 'success'
      //   });
      //   this.initLoad();
      // }).catch((error) => {
      //   this.$message({
      //     message: error.msg,
      //     type: 'error'
      //   });
      // })
    },


    //tabs 点击搜索
    keyWordMethod() {
      console.log(this.tabsProp.keywords);//搜索的值

      this.tableProp.page.current_page = 1;

      this.initLoad()
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod: function (val) {
      this.tableProp.page.current_page = val==''?1:val;
      this.tabsProp.heighKewWordField.page = this.tableProp.page.current_page
      this.tabsProp.heighKewWordField.create_audit_status = this.tabsProp.active

      let param = JSON.parse(JSON.stringify(this.tabsProp.heighKewWordField))

      if (param.start_time){
        param.start_time = this.formatDate(param.start_time/1000);

      }
      if (param.end_time){
        param.end_time = this.formatDate(param.end_time/1000);

      }

      this.initLoad(param)
    },





    formatDate(item_date) {
      let date = new Date(parseInt(item_date) * 1000);
      let YY = date.getFullYear() + "-";
      let MM =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      // let hh =
      //     (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      // let mm =
      //     (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      //     ":";
      // let ss =
      //     date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD;
    },



  },
};
</script>
<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>
