<template>
  <div>
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>项目指派</span>
      </div>

    </div>
    <!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @detileApproval="detileApproval"
    />
  </div>
</template>
<script>
import {getAppointProjectPage} from "../../../api/module/approval/common";
export default {
  components: {},
  props: [],
  data() {
    return {
      //tabs 数据源
      tabsProp: {
        active: '1',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: false,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索的字段
        heighKewWordField: {status: '', project_title: '', type_id: '', name: '', customer: '',},
        //tabs 标题
        tabsData: [{
          label: '待指派',
          name: '1',
          total: 0,
        },{
          label: '已指派',
          name: '3',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '项目编号',
          field: 'project_sn',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目名称',
          field: 'project_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目类型',
          field: 'type_id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '揭榜部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },  {
          title: '计划成单时间',
          field: 'start_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '计划交付时间',
          field: 'end_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        },{
          title: '揭榜人',
          field: 'memberList',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '220px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '查看',
            type: 'primary',
            method: 'detileApproval',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth:'120px',
      //表格数据
      tableData: [],
      //addDialog提交数据
      subData: {}
    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        status:this.tabsProp.active
      }
      getAppointProjectPage(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        this.tableData = res.data
        for (let i = 0; i < this.tableData.length; i++) {
          let memberList = '';
          if(this.tableData[i].project_item.length!=0) {
            for(let j=0;j<this.tableData[i].project_item.length;j++){
              memberList+=this.tableData[i].project_item[j].user.name+";"
            }
          }else {
              memberList = '暂无人揭榜';
          }
          //揭榜部门
          if(this.tableData[i].dev_user_station!=null){
            this.tableData[i].department_name = this.tableData[i].dev_user_station.department_name
          }else {
            this.tableData[i].department_name = '暂无人揭榜'
          }
          this.tableData[i].memberList = memberList;
          this.tableData[i].type_id = this.tableData[i].project_type.type_name
        }
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(tab.name)
    },

    //table 详情 按钮点击
    detileApproval(row, index) {
      this.$router.push({path:'./assign/edit',query:{id:row.id}})
      // this.$router.push({path:'/project_management/project_detail', query:{id:row.id}})
      console.log(row)
      console.log(index)

    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
  },
};
</script>
<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>
