<template>
  <div>
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>转正申请</span>
      </div>

    </div>
    <!--addDialog 组件 -->
    <common-add-dialog
        :addDialogData="addDialogData"
        @addDialogSub="close"

    />

    <!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @detileApproval="detileApproval"
        @passOrder="passOrder"
    />

    <el-dialog
        title="审批"
        :visible.sync="dialogFormVisible"
        :append-to-body="true"
    >
      <el-form :model="form">

        <el-form-item label="" :label-width="formLabelWidth">
          <el-radio-group v-model="form.status">
            <el-radio label="1">通过</el-radio>
            <el-radio label="2">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="form.status=='1'">
          <el-form-item label="转正日期" :label-width="formLabelWidth">
            <el-date-picker
                type="date" placeholder="请选择日期" v-model="form.regular_at"
                value-format="timestamp"
                style="width: 100%;"></el-date-picker>
          </el-form-item>
        </div>
        <div v-else-if="form.status=='2'">
          <el-form-item label="审批意见" :label-width="formLabelWidth">
            <el-input type="text" v-model="form.consent_information_json.fail_reasons"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogSub">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>
<script>
import {getRegularList} from "../../../api/module/approval/common";
import {auditStep1} from "../../../api/module/project/project_func";

export default {
  components: {},
  props: [],
  data() {
    return {
      //addDialog数据源
      addDialogData: {
        isDialog: false,//dialog是否显示
        subMethod: 'addDialogSub',//点击确定方法名
        title: '转正审核',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 数据源
        addDialogProp: [{
          label: '头像',
          field: 'avatar',
          type: 7,
          show: true,
        },{
          label: '姓名',
          field: 'user_name',
          type: 1,
          show: true,
        },{
          label: '工作总结',
          field: 'work_summary',
          type: 1,
          show: true,
        },{
          label: '岗位理解',
          field: 'work_understanding',
          type: 1,
          show: true,
        },{
          label: '不通过原因',
          field: 'no_reason',
          type: 1,
          show: true,
        },
        ],
        //dialog 显示数据
        addDialogShowData: {
          avatar:{ //图片要用
            isAll:false,//是否多图上传 不是默认是否， 需要多图上传改成true
            img: [],
          },
          user_name:'sss',
          work_summary:'',
          work_understanding:'',
          no_reasons:''
        },

      },
      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: false,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        // 状态 下拉的值
        statusOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],
        // 项目类型 下拉的值
        type_idOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],
        // 商务负责人 下拉的值
        nameOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],

        //高级搜索的字段
        heighKewWordField: {status: '', project_title: '', type_id: '', name: '', customer: '',},
        //tabs 标题
        tabsData: [{
          label: '待审核',
          name: '0',
          total: 0,
        }, {
          label: '已通过',
          name: '1',
          total: 0,
        }, {
          label: '未通过',
          name: '2',
          total: 0,
        }, {
          label: '重新审核',
          name: '3',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: true,//是否需要有序号
        selection: true,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '工号',
          field: 'user_id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '照片',
          field: 'avatar',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 1,
        }, {
          title: '姓名',
          field: 'name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '职级',
          field: 'class_of_positions',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '岗位',
          field: 'station_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '状态',
          field: 'audit_status_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '220px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 20,
          tableOperate: [{
            name: '查看',
            type: 'primary',
            method: 'detileApproval',
            status: '20000',
            key: 'detail',
            id: 20000,
          }, {
            name: '审批',
            type: 'danger',
            method: 'passOrder',
            key: 'check',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        status: "1",
        id: '',
        regular_at: '',
        consent_information_json:{
          fail_reasons:''
        }
      },
      formLabelWidth: '120px',
      //表格数据
      tableData: [],
      //addDialog提交数据
      subData: {}
    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        status: this.tabsProp.active
      }
      getRegularList(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].avatar = JSON.parse(res.data[i].user_apply1.avatar).img[0]
          res.data[i].name = res.data[i].user_apply1.name
          if (res.data[i].user_apply != null) {
            res.data[i].department_name = res.data[i].user_apply.department_name
            res.data[i].station_name = res.data[i].user_apply.station_name
            res.data[i].class_of_positions = res.data[i].user_apply.class_of_positions
            res.data[i].job_status = res.data[i].user_apply.job_status
          } else {
            res.data[i].department_name = '暂无信息'
            res.data[i].station_name = '暂无信息'
            res.data[i].class_of_positions = '暂无信息'
            res.data[i].job_status = '未通过或待审核'
          }
        }
        this.tableData = res.data
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].audit_status_name = '待审核'
          if (this.tableData[i].audit_info.status==1){
            this.tableData[i].audit_status_name = '审核通过'
          }else if(this.tableData[i].audit_info.status==2){
            this.tableData[i].audit_status_name = '审核不通过'
          }

        }
      })
    },
    //tabs 切换点击
    tabsClick() {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },
    close(){
      this.addDialogData.isDialog = false;
    },
    //table 详情 按钮点击
    detileApproval(row) {
      this.addDialogData.isDialog = true;
      this.addDialogData.addDialogShowData.avatar.img=row.avatar
      this.addDialogData.addDialogShowData.user_name=row.name
      this.addDialogData.addDialogShowData.work_summary=row.work_summary
      this.addDialogData.addDialogShowData.work_understanding=row.work_understanding
      this.addDialogData.addDialogShowData.no_reason=row.no_reason



    },
    //table 确认成单 按钮点击
    passOrder(row, index) {

      this.dialogFormVisible = true;
      this.form.id = row.audit_info.id;
      this.form.status = row.status.toString();
      this.form.regular_at = row.regular_at*1000;

      console.log(row)
      console.log(index)

    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
    // addDialog 点击确定
    addDialogSub() {
      console.log(this.form)
      let data = {...this.form}
      data.regular_at = data.regular_at / 1000;
      data.status = parseInt(data.status);
      data.consent_information_json = JSON.stringify(data.consent_information_json);
      auditStep1(data).then(res => {
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.dialogFormVisible = false;
        this.initLoad()

      }).catch((error) => {
        this.$message({
          message: error.msg,
          type: 'error'
        });
      })

    },


  },
};
</script>
<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>
