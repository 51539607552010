<template>
  <div>
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>离职审批</span>
      </div>

    </div>
    <!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @openModel="openModel"
    />

<!--    详情模态框-->
    <el-dialog
        title="离职申请"
        :visible.sync="leaveData.visible"
        width="50%"
        :append-to-body="true">
      <div class="common-add-dialog">
        <ul class="cdul">
          <li>
            <span :style="{'width':leaveData.labelWidth}">申请人</span>
            <div>{{leaveData.addDialogShowData.user_name}}</div>
          </li>
          <li>
            <span :style="{'width':leaveData.labelWidth}">离职描述</span>
            <div>{{leaveData.addDialogShowData.reason}}</div>
          </li>
          <div style="padding: 20px 5px; font-weight: 600">我主导的项目-未完成</div>
          <div style="padding-left: 20px" v-if="leaveData.addDialogShowData.projectInfo.length == 0">目前没有未完成的项目</div>
          <common-table
              :tableData="leaveData.addDialogShowData.projectInfo"
              :tableProp="leaveProTableProp"
              style="margin: 0px 20px"
              v-else
          />
          <div style="padding: 20px 5px; font-weight: 600">我的任务-未完成</div>
          <div style="padding-left: 20px" v-if="leaveData.addDialogShowData.taskInfo.length == 0">目前没有未完成的任务</div>
          <common-table
              :tableData="leaveData.addDialogShowData.taskInfo"
              :tableProp="leaveTaskTableProp"
              style="margin: 0px 20px"
              v-else
          />
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="leaveData.visible=false">取 消</el-button>
        <el-button type="danger" @click="subLeave(leaveData.addDialogShowData.id , 3)" v-if="tabsProp.active == 2">不通过</el-button>
        <el-button type="primary" @click="subLeave(leaveData.addDialogShowData.id , 1)" v-if="tabsProp.active == 2">通 过</el-button>
      </div>
    </el-dialog>


  </div>
</template>
<script>
import {userLeave, userLeaveApproval, userLeaveDetail} from "../../../api/module/approval/common";
export default {
  components: {},
  props: [],
  data() {
    return {
      //tabs 数据源
      tabsProp: {
        active: '2',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: false,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        // 状态 下拉的值
        statusOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],
        // 项目类型 下拉的值
        type_idOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],
        // 商务负责人 下拉的值
        nameOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],

        //高级搜索的字段
        heighKewWordField: {status: '', project_title: '', type_id: '', name: '', customer: '',},
        //tabs 标题
        tabsData: [{
          label: '待审批',
          name: '2',
          total: 0,
        },{
          label: '审核通过',
          name: '1',
          total: 0,
        }, {
          label: '审核不通过',
          name: '3',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: true,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '工号',
          field: 'user_station',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'job_num',
        }, {
          title: '姓名',
          field: 'user_info',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name',
        }, {
          title: '部门',
          field: 'user_station',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'department_name',
        }, {
          title: '岗位',
          field: 'user_station',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'station_name',
        }, {
          title: '发起时间',
          field: 'created_at',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '详情',
            type: 'primary',
            method: 'openModel',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      //表格数据
      tableData: [],

      //离职申请
      leaveData:{
        visible: false,
        labelWidth: "120px",
        addDialogShowData:{
          user_name: '',
          reason: '',
          projectInfo: [],
          taskInfo: [],
        }
      },
      //离职项目交接表格
      leaveProTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '项目名称',
          field: 'project_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '接收人',
          field: 'accept_username',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }],
        accept_user_idProp: []
      },
      //离职任务交接表格
      leaveTaskTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '任务名称',
          field: 'task_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目名称',
          field: 'project_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '接收人',
          field: 'accept_username',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }],
        accept_user_idProp: []
      },
    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {

    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        status:this.tabsProp.active
      }

      userLeave(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        this.tableData = res.data
      })
    },
    //tabs 切换点击
    tabsClick() {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },

    //table 审批 按钮点击
    openModel(row) {
      console.log(row)
      userLeaveDetail(row.id).then((res)=>{
          console.log(res)
        this.leaveData.addDialogShowData = res.data
        this.leaveData.visible = true
      })
    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },

  //  审批通过
    subLeave(id , status){
      let msg = '确定审批通过？'
      if (status == 3){
        msg = '确定审批不通过？'
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id : id ,
          status : status ,
        }
        userLeaveApproval(data).then(()=>{
          this.$message({
            message: "操作成功！",
            type: 'success'
          });
          this.leaveData.visible = false
          this.initLoad();
        })
      })

    },

  },
};
</script>
<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
.cdul{
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 120px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }


  }
}
.common-add-dialog {
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 120px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }

  }
}
</style>
