import http from '../../http';

//立项项目列表--审核列表
export function getNewProjectPage(params) {
    return http({//获取用户列表
        url: "/api/project/getNewProjectPage",
        method: "get",
        params: params
    })
}

//成单项目审批列表
export function getDealProjectPage(params) {
    return http({//获取用户列表
        url: "/api/project/getDealProjectPage",
        method: "get",
        params: params
    })
}

export function getAuditPerData(params) {
    return http({//获取用户列表
        url: "/api/base/auditPerData",
        method: "get",
        params: params
    })
}

// 指派分页
export function getAppointProjectPage(params) {
    return http({//获取用户列表
        url: "/api/project/appointProjectPage",
        method: "get",
        params: params
    })
}

//报销列表（报表专用）
export function getReimburseList(params) {
    return http({//获取用户列表
        url: "/api/approval/reimburse",
        method: "get",
        params: params
    })
}

//报销列表（审批专用）
export function getApprovalReimburseList(params) {
    return http({//获取用户列表
        url: "/api/reportStats/checkReimbursePage",
        method: "get",
        params: params
    })
}

//转正列表
export function getRegularList(params) {
    return http({//获取用户列表
        url: "/api/approval/regular",
        method: "get",
        params: params
    })
}

//
//转正审核
export function putRegular(data) {
    return http({//获取用户列表
        url: "/api/approval/regular",
        method: "post",
        data: data
    })
}

//报销审核
export function putReimburse(data) {
    return http({//获取用户列表
        url: "/api/approval/reimburse",
        method: "post",
        data: data
    })
}

//报销详情
export function getReimburseDetail(params) {
    return http({//获取用户列表
        url: "/api/approval/reimburseDetail",
        method: "get",
        params: params
    })
}

//项目指派
export function projectAppoint(data) {
    return http({//获取用户列表
        url: "/api/project/projectAppoint",
        method: "post",
        data: data
    })
}


//离职审批
export function userLeave(params) {
    return http({//获取用户列表
        url: "/api/user/userLeaveManager",
        method: "get",
        params: params
    })
}
//离职审批详情
export function userLeaveDetail(params) {
    return http({//获取用户列表
        url: "/api/user/userLeaveManager/"+params,
        method: "get",
    })
}

//离职审批详情
export function userLeaveApproval(data) {
    return http({//获取用户列表
        url: "/api/user/userLeaveManager/"+data.id,
        method: "put",
        data: data
    })
}
