import http from '../http';

export function projectSetList (params) {
    return http({
        url: "/api/project/itemsPage",
        method: "get",
        params:params
    })
}
export function projectSetDetail (params) {
    return http({
        url: "/api/project/items",
        method: "get",
        params:params
    })
}
export function addProjectSet (data) {
    return http({
        url: "/api/project/items",
        method: "post",
        data:data
    })
}
export function projectSetEdit (data) {
    return http({
        url: "/api/project/items",
        method: "put",
        data:data
    })
}
export function projectSetDel (data) {
    return http({
        url: "/api/project/items",
        method: "delete",
        data:data
    })
}
