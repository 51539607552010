<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"
        @addProjectSet="addProjectSet"
    /><!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
        @keyWordMethod="keyWordMethod"
        @highKeyWordMethod="highKeyWordMethod"
    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @handleSizeChange="handleSizeChange"
        @deleteProjectSet="deleteProjectSet"
        @editProjectSet="editProjectSet"
        @detailProjectSet="detailProjectSet"
    />

    <!--项目集模态框 组件 -->
    <el-dialog
        :append-to-body="true"
        :title="projectSetData.title"
        :visible.sync="projectSetData.isDialog"
        :top="projectSetData.top"
        :width="projectSetData.width"
    >
      <div class="common-add-dialog">
        <ul class="cdul">
          <li>
            <span class="must">项目集名称</span>
            <el-input v-model="projectSetData.addDialogShowData.items_title" placeholder="请输入项目集名称" :disabled="projectSetData.type==1||projectSetData.type==3?false:true"></el-input>
          </li>
          <li>
            <span class="must">项目集类型</span>
            <el-select v-model="projectSetData.addDialogShowData.items_type" placeholder="请选择项目集类型" :disabled="projectSetData.type==1||projectSetData.type==3?false:true">
              <el-option
                  v-for="(itemOption,keyOption) in tabsProp.items_typeOptions"
                  :key="keyOption"
                  :label="itemOption.label"
                  :value="itemOption.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="must">项目集说明</span>
            <el-input type="textarea" v-model="projectSetData.addDialogShowData.description" placeholder="请输入项目集说明" :disabled="projectSetData.type==1||projectSetData.type==3?false:true"></el-input>
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="projectSetData.isDialog=false">取 消</el-button>
        <el-button type="primary" @click="subProjectSet" v-if="projectSetData.type!==4">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getProjectType} from "../../../api/project_management/new_project";
import {
  projectSetList,
  addProjectSet,
  projectSetDel,
  projectSetEdit,
  projectSetDetail
} from "@/api/project_management/project_set";

export default {
  components: {},
  props: [],
  data() {
    return {
      approvalDialogvisible: false,
      layUpdialogvisible: false,
      reductionVisible: false,
      //title 数据源
      titleProp: {
        name: '项目集列表',//标题名称
        butOperate: [{
          name:'新增',
          type: "primary",
          method: 'addProjectSet',
          id: 2000,
        }]
      },
      //tabs 数据源
      tabsProp: {
        active: '1',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: true,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [{
          name: '项目类型',
          field: 'items_type',
          type: 3,
        },  {
          name: '项目集名称',
          field: 'items_title',
          type: 1,
        }],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        items_typeOptions: [],
        //高级搜索的字段
        heighKewWordField: {items_title: '', items_type: ''},
        //tabs 标题
        //默认全部，1=不成单，2=立项待审批， 3=待成单，4=待揭榜，5=待完工，6=待验收，7=待结题-技术，8=待结题-市场,9=待结算，10=完成
        tabsData: [{
          label: '全部',
          name: '1',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: true,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'btn_delete',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          type: 2,
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
          method2: 'handleSizeChange',
        },
        table: [{
          title: '项目集名称',
          field: 'items_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目类型',
          field: 'items_type',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'type_name'
        }, {
          title: '项目数量',
          field: 'num',
          isWidth: false,
          width: '110px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '进行中',
          field: 'afoot',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '待结算',
          field: 'wait',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '已完成',
          field: 'completed',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '不成单',
          field: 'noOrder',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '创建人',
          field: 'user_info',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name'
        }, {
          title: '创建时间',
          field: 'created_at',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '操作',
          isWidth: true,
          width: '245px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '查看',
            type: 'primary',
            method: 'detailProjectSet',
            status: '20000',
            id: 20000,
          }, {
            name: '修改',
            type: 'primary',
            method: 'editProjectSet',
            status: '20000',
            id: 20000,
          }, {
            name: '删除',
            type: 'danger',
            method: 'deleteProjectSet',
            status: '1',
            id: 20000,
          }],
        }],
      },
      tableData: [],
      //任务列表数据源
      taskTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '责任人',
          field: 'uid',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 8,
        }, {
          title: '任务名称',
          field: 'task_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
        }, {
          title: '开始时间',
          field: 'expect_start_time',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
        }, {
          title: '截止时间',
          field: 'expect_finish_time',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
        }, {
          title: '绩效值',
          field: 'achievements',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '删除',
            type: 'danger',
            method: 'deleteTask',
            status: '20000',
            id: 20000,
          }],

        }],
        uidProp: [],
      },
      taskTableDetailProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '责任人',
          field: 'ze_ren_ren',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name'
        }, {
          title: '任务名称',
          field: 'task_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '开始时间',
          field: 'expect_start_time',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '截止时间',
          field: 'expect_finish_time',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '绩效值',
          field: 'achievements',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }],
      },
      //项目集模态框
      projectSetData: {
        type: 1,  //1-增加，2-删除，3-修改，4-查看
        levelText: '',
        isDialog: false,//dialog是否显示
        title: '新增项目集',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '130px', // label 长度
        //dialog 显示数据
        addDialogShowData: {
          items_title:'',
          items_type:'',
          description:'',
        },
      },
      //项目类型下拉
      typeOptions:[]
    };
  },
  created() {
    this.initLoad();
    this.getProjectType();
  },
  mounted() {
  },
  methods: {
    getProjectType(){
      getProjectType().then(res=>{
        this.tabsProp.items_typeOptions=res.data.map(item => ({
          label: item.type_name,
          value: item.id,
        }))
      })
    },
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        per_page: this.tableProp.page.size
      }

      if(this.tabsProp.heighKewWordField.items_type!=""){
        params.items_type = this.tabsProp.heighKewWordField.items_type
      }
      if(this.tabsProp.heighKewWordField.items_title!=""){
        params.items_title = this.tabsProp.heighKewWordField.items_title
      }
      projectSetList(params).then((result) => {
        console.log(result)
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = Number(res.per_page);

        this.tableData = res.data
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      // this.tabsProp.active //tabs 当前的值
      console.log(tab.name)
      if (tab.name == 2){
        // this.tableProp.table[]
      }
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },

    //新增项目集
    addProjectSet(){
      this.projectSetData.addDialogShowData = this.$options.data().projectSetData.addDialogShowData
      this.projectSetData.type = 1
      this.projectSetData.title = "新增项目集"
      this.projectSetData.isDialog = true
    },
    //新增项目集模态框提交
    subProjectSet(){
      console.log(this.projectSetData.addDialogShowData)
      if (!this.projectSetData.addDialogShowData.items_title || !this.projectSetData.addDialogShowData.items_type || !this.projectSetData.addDialogShowData.description ){
        this.$confirm("*为必填项信息，请填写完整后提交！", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        return false
      }

      if (this.projectSetData.type == 1){
        addProjectSet(this.projectSetData.addDialogShowData).then(()=>{
          this.$message({
            message: '添加成功！',
            type: 'success'
          })
          this.projectSetData.isDialog = false
          this.initLoad()
        })
      } else if (this.projectSetData.type == 3){

        projectSetEdit(this.projectSetData.addDialogShowData).then(()=>{
          this.$message({
            message: '修改成功！',
            type: 'success'
          })
          this.projectSetData.isDialog = false
          this.initLoad()
        })
      }

    },

    //table 修改 按钮点击
    editProjectSet(row){
      this.projectSetData.addDialogShowData = this.$options.data().projectSetData.addDialogShowData
      this.projectSetData.type = 3
      this.projectSetData.title = "修改项目集"

      projectSetDetail({id: row.id}).then(res=>{
        this.projectSetData.addDialogShowData.items_title = res.data.items_title
        this.projectSetData.addDialogShowData.items_type = Number(res.data.items_type.id)
        this.projectSetData.addDialogShowData.description = res.data.description
        this.projectSetData.addDialogShowData.id = res.data.id
        this.projectSetData.isDialog = true
      })
    },
    //table 详情 按钮点击
    detailProjectSet(row) {
      this.projectSetData.addDialogShowData = this.$options.data().projectSetData.addDialogShowData
      this.projectSetData.type = 4
      this.projectSetData.title = "项目集详情"

      projectSetDetail({id: row.id}).then(res=>{
        this.projectSetData.addDialogShowData.items_title = res.data.items_title
        this.projectSetData.addDialogShowData.items_type = Number(res.data.items_type.id)
        this.projectSetData.addDialogShowData.description = res.data.description
        this.projectSetData.isDialog = true
      })
    },
    //table 删除 按钮点击
    deleteProjectSet(row) {
      this.$confirm('此操作为删除项目集，是否继续？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row)
        projectSetDel({id: row.id}).then(()=>{
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.initLoad()
        })
      })

    },

    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      this.initLoad();
    },
    handleSizeChange(val) {
      this.tableProp.page.size = val
      this.initLoad();
    },

  },
};
</script>

<style>
.custom-tree-container{
  width: 300px;
}
.custom-tree-node{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>

<style lang="scss" scoped>
.cdul{
  width: 90%;
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 130px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }


  }
}
.common-add-dialog {
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 130px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }

  }
}


</style>
