<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"
        @addSj="addSj"
    /><!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
        @keyWordMethod="keyWordMethod"
        @highKeyWordMethod="highKeyWordMethod"
    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @handleSizeChange="handleSizeChange"
        @deleteBussiness="deleteBussiness"
        @editBusiness="editBusiness"
        @detailBusiness="detailBusiness"
        @layUpproject="layUpproject"
        @approvalProject="approvalProject"
        @reductionProject="reductionProject"
    />

    <!--商机模态框 组件 -->
    <el-dialog
        :append-to-body="true"
        :title="sjData.title"
        :visible.sync="sjData.isDialog"
        :top="sjData.top"
        :width="sjData.width"
    >
      <div class="common-add-dialog">
        <ul class="cdul">
          <li style="width: 50%">
            <span class="required2">项目负责人</span>
            <el-select v-model="ruleForm.uid" placeholder="请选择项目负责人" :disabled="sjData.type==1||sjData.type==3?false:true">
              <el-option
                  v-for="(itemOption,keyOption) in taskTableProp.uidProp"
                  :key="keyOption"
                  :label="itemOption.label"
                  :value="itemOption.value">
              </el-option>
            </el-select>

<!--            <span v-if="sjData.type!==1 && sjData.type!==3" style="position: absolute; right: 44%">
              变更前为：
            </span>-->
          </li>
          <li style="width: 50%;">
            <span class="required2">项目名称</span>
            <el-input v-model="ruleForm.title" placeholder="请输入项目名称" :disabled="sjData.type==1||sjData.type==3?false:true"></el-input>

          </li>
          <li style="width: 100%;">

            <!--            <span class="required2">项目类型</span>
                        <el-select v-model="ruleForm.type" placeholder="请选择项目类型" :disabled="sjData.type==1||sjData.type==3?false:true">
                          <el-option
                              v-for="(itemOption,keyOption) in typeOptions"
                              :key="keyOption"
                              :label="itemOption.label"
                              :value="itemOption.value">
                          </el-option>
                        </el-select>-->
            <span class="required2">计划开始时间</span>
            <el-date-picker type="date" placeholder="请选择计划开始时间" v-model="ruleForm.expect_start_time" value-format="timestamp"
                            style="width: 100%;" @change="getDay('expect_start_time')" :disabled="sjData.type==1||sjData.type==3?false:true"></el-date-picker>



            <span class="required2">计划完成时间</span>
            <el-date-picker type="date" placeholder="请选择计划完成时间" v-model="ruleForm.expect_finish_time" value-format="timestamp"
                            style="width: 100%" @change="getDay('expect_finish_time')" :disabled="sjData.type==1||sjData.type==3?false:true"></el-date-picker>
          </li>
          <li :style='{width : sjData.type == 3 || sjData.type == 4?"100%":"50%"}'>

            <span class="required2">预计项目金额(万元)</span>
            <el-input v-model="ruleForm.expect_amount" placeholder="请输入预计项目金额" :disabled="sjData.type==1||sjData.type==3?false:true"></el-input>

            <template v-if="sjData.type == 3 || sjData.type == 4">
              <span>计划延迟时间</span>
              <el-date-picker type="date" placeholder="请选择计划延迟时间" v-model="ruleForm.expect_delay_time" value-format="timestamp"
                              style="width: 100%;" @change="getDay('expect_delay_time')" :disabled="sjData.type==1||sjData.type==3?false:true"></el-date-picker>
            </template>
          </li>
          <li v-if="sjData.type == 4">
            <span>总天数</span>
            <el-input v-model="ruleForm.totalDay" disabled></el-input>

            <span>超时天数</span>
            <el-input v-model="ruleForm.timeoutDay" disabled></el-input>
          </li>
          <li>
              <span>客户名称</span>
              <el-input v-model="ruleForm.customer_name" placeholder="请输入客户名称" :disabled="sjData.type==1||sjData.type==3?false:true"></el-input>

              <span>项目联系人</span>
              <el-input v-model="ruleForm.project_contacts" placeholder="请输入项目联系人" :disabled="sjData.type==1||sjData.type==3?false:true"></el-input>
          </li>
          <li style="width: 50%">
              <span>联系方式</span>
              <el-input v-model="ruleForm.customer_contact_info" placeholder="请输入联系方式" :disabled="sjData.type==1||sjData.type==3?false:true"></el-input>
          </li>
          <li>
              <span>项目说明</span>
              <el-input type="textarea" v-model="ruleForm.project_description" placeholder="请输入项目说明" :disabled="sjData.type==1||sjData.type==3?false:true"></el-input>
          </li>
        </ul>

        <div style="border-bottom: 1px solid #b1c6e0; margin: 10px 0px; padding: 10px 0; color: #a5afc8; font-size: 14px;">任务列表</div>
        <el-button type="primary" @click="addTask" style="margin-bottom: 10px" v-if="sjData.type==1||sjData.type==3">添加任务</el-button>
        <common-table
            :tableData="ruleForm.task"
            :tableProp="taskTableProp"
            @deleteTask = "deleteTask"
            @expect_start_timeChange = "expect_start_timeChange"
            @expect_finish_timeChange = "expect_finish_timeChange"
            v-if="sjData.type==1||sjData.type==3"
        />
        <common-table
            :tableData="ruleForm.task"
            :tableProp="taskTableDetailProp"
            @starTask = "starTask"
            @endTask = "endTask"
            v-else
        />

        <div style="border-bottom: 1px solid #b1c6e0; margin: 10px 0px; padding: 10px 0; color: #a5afc8; font-size: 14px;" v-if="sjData.type==4">商机记录</div>
        <ul v-if="sjData.type==4 && ruleForm.shang_ji_ji_lu !== undefined && ruleForm.shang_ji_ji_lu.length > 0">
          <li v-for="(item, key) in ruleForm.shang_ji_ji_lu" :key="key">
            <ul class="cdul" v-if="item.record_type == 1">
              <li>
                <span>提交人</span>
                {{item.ti_jiao_ren.name}}

                <span>还原日期</span>
                {{Utils.timeDate(item.operate_time)}}
              </li>
              <li>
                <span>还原说明</span>
                {{item.record_description}}
              </li>
            </ul>
            <ul class="cdul" v-else-if="item.record_type == 2">
              <li>
                <span>提交人</span>
                {{item.ti_jiao_ren.name}}

                <span>搁置日期</span>
                {{Utils.timeDate(item.operate_time)}}
              </li>
              <li>
                <span>搁置说明</span>
                {{item.record_description}}
              </li>
            </ul>
            <ul class="cdul" v-else-if="item.record_type == 3">
              <li>
                <span>提交人</span>
                {{item.ti_jiao_ren.name}}

                <span>立项日期</span>
                {{Utils.timeDate(item.operate_time)}}
              </li>
            </ul>
          </li>
        </ul>
        <div v-else-if="sjData.type==4">
          暂无记录
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="sjData.isDialog=false">取 消</el-button>
        <el-button type="primary" @click="subSj" v-if="sjData.type!==4">确 定</el-button>
      </div>
    </el-dialog>


    <!--搁置-->
    <el-dialog
        title="商机搁置"
        :visible.sync="layUpdialogvisible"
        width="500px"
        :append-to-body="true">
      <el-form ref="form"  label-width="80px">
        <el-form-item label="搁置日期" class="required">
          <el-date-picker type="date" placeholder="请选择搁置日期" v-model="businessOperationData.operate_time" value-format="timestamp"
                          style="width: 100%;" ></el-date-picker>
        </el-form-item>
        <el-form-item label="搁置说明">
          <el-input type="textarea" v-model="businessOperationData.record_description" placeholder="请输入搁置说明"></el-input>
        </el-form-item>
      </el-form>


      <span slot="footer" class="dialog-footer">
          <el-button @click="layUpdialogvisible = false">取 消</el-button>
          <el-button type="primary" @click="subLayup">确 定</el-button>
        </span>
    </el-dialog>

    <!--还原-->
    <el-dialog
        title="商机还原"
        :visible.sync="reductionVisible"
        width="500px"
        :append-to-body="true">
      <el-form ref="form"  label-width="80px">
        <el-form-item label="还原日期" class="required">
          <el-date-picker type="date" placeholder="请选择还原日期" v-model="businessOperationData.operate_time" value-format="timestamp"
                          style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="还原说明">
          <el-input type="textarea" v-model="businessOperationData.record_description" placeholder="请输入还原说明"></el-input>
        </el-form-item>
      </el-form>


      <span slot="footer" class="dialog-footer">
          <el-button @click="reductionVisible = false">取 消</el-button>
          <el-button type="primary" @click="subReduction">确 定</el-button>
        </span>
    </el-dialog>

  </div>
</template>
<script>
import {getProjectType} from "../../../api/project_management/new_project";
import {getshenPr} from "@/api/basic_management/template_management";
import {
  businessDel,
  businessEdit,
  businessList,
  businessOperation,
  businessSub, taskOperation,
} from "@/api/project_management/business_management";

export default {
  components: {},
  props: [],
  data() {
    return {
      approvalDialogvisible: false,
      layUpdialogvisible: false,
      reductionVisible: false,
      //商机数据
      ruleForm: {
        uid: '',
        title: '',
        expect_amount: '',
        customer_name: '',
        customer_contact_info: '',
        expect_start_time: '',
        expect_finish_time: '',
        expect_delay_time: '',
        project_contacts: '',
        project_description: '',
        task: [],
        timeoutDay: '',
        totalDay: '',
      },
      //搁置，还原，立项数据
      businessOperationData:{
        id: '',
        record_type: 1,   //1-还原 2-搁置 3-立项
        operate_time: '',
        record_description: '',
      },
      //title 数据源
      titleProp: {
        name: '商机列表',//标题名称
        butOperate: [{
          name:'新增',
          type: "primary",
          method: 'addSj',
          id: 2000,
        }]
      },
      //tabs 数据源
      tabsProp: {
        active: '1',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: true,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [{
          name: '项目负责人',
          field: 'uid',
          type: 3,
        },  {
          name: '项目名称',
          field: 'projectName',
          type: 1,
        }],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        uidOptions: [],
        //高级搜索的字段
        heighKewWordField: {projectName: '', uid: ''},
        //tabs 标题
        //默认全部，1=不成单，2=立项待审批， 3=待成单，4=待揭榜，5=待完工，6=待验收，7=待结题-技术，8=待结题-市场,9=待结算，10=完成
        tabsData: [{
          label: '待立项',
          name: '1',
          total: 0,
        },{
          label: '商机搁置',
          name: '2',
          total: 0,
        },{
          label: '已立项',
          name: '3',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: true,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'btn',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          type: 2,
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
          method2: 'handleSizeChange',
        },
        table: [{
          title: '项目名称',
          field: 'title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目负责人',
          field: 'fu_ze_ren',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name'
        }, {
          title: '预计项目金额(万元)',
          field: 'expect_amount',
          isWidth: false,
          width: '110px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },
        {
          title: '计划开始时间',
          field: 'expect_start_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '计划完成时间',
          field: 'expect_finish_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '计划延迟时间',
          field: 'expect_delay_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '总天数',
          field: 'totalDay',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '超时天数',
          field: 'timeoutDay',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '当前任务',
          field: 'current_task',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: true,
          width: '245px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '查看',
            type: 'primary',
            method: 'detailBusiness',
            status: '20000',
            id: 20000,
          }, {
            name: '修改',
            type: 'primary',
            method: 'editBusiness',
            status: '11, 12',
            id: 20000,
          }, {
            name: '立项',
            type: 'primary',
            method: 'approvalProject',
            status: '11',
            id: 20000,
          }, {
            name: '搁置',
            type: 'primary',
            method: 'layUpproject',
            status: '11',
            id: 20000,
          }, {
            name: '还原',
            type: 'primary',
            method: 'reductionProject',
            status: '12',
            id: 20000,
          }, {
            name: '删除',
            type: 'danger',
            method: 'deleteBussiness',
            status: '11,12,13',
            id: 20000,
          }],
        }],
      },
      tableData: [],
      //任务列表数据源
      taskTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '责任人',
          field: 'uid',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 8,
          labelClassName: 'required3'
        }, {
          title: '任务名称',
          field: 'task_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '开始时间',
          field: 'expect_start_time',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
          labelClassName: 'required3'
        }, {
          title: '截止时间',
          field: 'expect_finish_time',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
          labelClassName: 'required3'
        }, {
          title: '绩效值',
          field: 'achievements',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '删除',
            type: 'danger',
            method: 'deleteTask',
            status: '20000',
            id: 20000,
          }],

        }],
        uidProp: [],
      },
      taskTableDetailProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'btn',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '责任人',
          field: 'ze_ren_ren',
          isWidth: false,
          width: '60px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name'
        }, {
          title: '任务名称',
          field: 'task_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '开始时间',
          field: 'expect_start_time',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '截止时间',
          field: 'expect_finish_time',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '总天数',
          field: 'expect_days',
          isWidth: false,
          width: '50px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '实际开始时间',
          field: 'actual_start_time',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '实际完成时间',
          field: 'actual_finish_time',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '实际天数',
          field: 'actual_day',
          isWidth: false,
          width: '50px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '当前进度',
          field: 'nowStatus',
          isWidth: false,
          width: '80px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '绩效值',
          field: 'achievements',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '开始',
            type: 'danger',
            method: 'starTask',
            status: '11',
            id: 20000,
          }, {
            name: '完成',
            type: 'danger',
            method: 'endTask',
            status: '12',
            id: 20000,
          }],

        }],
        uidProp: [],
      },
      //商机模态框
      sjData: {
        type: 1,  //1-增加，2-删除，3-修改，4-查看
        levelText: '',
        isDialog: false,//dialog是否显示
        title: '新增商机',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '1100px', // dialog 长度，可以是百分比
        labelWidth: '130px', // label 长度
        //dialog 显示数据
        addDialogShowData: {
          id:'',
          project_name:'123',
          project_sn:'123',
          deal_sn:'',
          security_level:'',
          deal_price:'',
          start_time:'',
          end_time:'',
          cycle_time:'',
          source_cost:'',
          customer:'',
          customer_tel:'',
          deal_attachment_json: [],
        },
      },
      //项目类型下拉
      typeOptions:[]
    };
  },
  created() {
    this.initLoad();
    this.getProjectType();
    this.getAllUser();
  },
  mounted() {
  },
  methods: {
    getProjectType(){
      getProjectType().then(res=>{
        this.typeOptions=res.data.map(item => ({
          label: item.type_name,
          value:(item.id).toString()
        }))
      })
    },
    getAllUser(){
      getshenPr().then(res=>{
        this.tabsProp.uidOptions=res.data.map(item => ({
          label: item.name,
          value:(item.id).toString()
        }))
        this.taskTableProp.uidProp=res.data.map(item => ({
          label: item.name,
          value:(item.id).toString()
        }))
      })
    },
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        status: this.tabsProp.active,
        page: this.tableProp.page.current_page,
        per_page: this.tableProp.page.size
      }

      if(this.tabsProp.heighKewWordField.projectName!=""){
        params.title = this.tabsProp.heighKewWordField.projectName
      }
      if(this.tabsProp.heighKewWordField.uid!=""){
        params.uid = this.tabsProp.heighKewWordField.uid
      }
      businessList(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = Number(res.per_page);
        let nowData = new Date().getTime()
        for (let i = 0; i < res.data.length; i++){
          res.data[i].totalDay = Math.trunc((res.data[i].expect_finish_time - res.data[i].expect_start_time)/(24*60*60))
          if (nowData >= res.data[i].expect_finish_time*1000){
            res.data[i].timeoutDay = Math.trunc((nowData - res.data[i].expect_finish_time*1000)/(24*60*60*1000))
          } else {
            res.data[i].timeoutDay = 0
          }
          if (res.data[i].ren_wu_lie_biao.length>0){
            res.data[i].current_task = res.data[i].ren_wu_lie_biao[0].task_title
          } else {
            res.data[i].current_task = ''
          }
          res.data[i].btn = res.data[i].all_btn.toString() + res.data[i].status.toString()
        }
        console.log(res.data)
        this.tableData = res.data
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      // this.tabsProp.active //tabs 当前的值
      console.log(tab.name)
      if (tab.name == 2){
        // this.tableProp.table[]
      }
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },

    //新增商机
    addSj(){
      this.ruleForm = this.$options.data().ruleForm
      this.sjData.type = 1
      this.sjData.title = "新增商机"
      this.sjData.isDialog = true
    },
    //新增商机模态框提交
    subSj(){
      console.log(this.ruleForm)
      if (!this.ruleForm.uid || !this.ruleForm.title || !this.ruleForm.expect_amount || !this.ruleForm.expect_start_time || !this.ruleForm.expect_finish_time ||
          !this.ruleForm.task[0] ){
        this.$confirm("*为必填项信息，请填写完整后提交！", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        return false
      }
      for (let i = 0; i < this.ruleForm.task.length; i++) {
        if (!this.ruleForm.task[i].uid || !this.ruleForm.task[i].task_title || !this.ruleForm.task[i].expect_start_time ||
            !this.ruleForm.task[i].expect_finish_time || !this.ruleForm.task[i].achievements){
          this.$confirm("任务信息填写不完整，请检查！", "提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          return false
        }
        this.ruleForm.task[i].expect_start_time = this.ruleForm.task[i].expect_start_time/1000
        this.ruleForm.task[i].expect_finish_time = this.ruleForm.task[i].expect_finish_time/1000
      }
      this.ruleForm.expect_start_time = this.ruleForm.expect_start_time/1000
      this.ruleForm.expect_finish_time = this.ruleForm.expect_finish_time/1000
      this.ruleForm.expect_delay_time = this.ruleForm.expect_delay_time/1000
      this.ruleForm.task = JSON.stringify(this.ruleForm.task)
      if (this.sjData.type == 1){
        businessSub(this.ruleForm).then(()=>{
          this.$message({
            message: '添加成功！',
            type: 'success'
          })
          this.sjData.isDialog = false
          this.initLoad()
        })
      } else if (this.sjData.type == 3){

        businessEdit(this.ruleForm).then(()=>{
          this.$message({
            message: '修改成功！',
            type: 'success'
          })
          this.sjData.isDialog = false
          this.initLoad()
        })
      }

    },

    //table 修改 按钮点击
    editBusiness(row){
      this.ruleForm = this.$options.data().ruleForm
      this.sjData.type = 3
      this.sjData.title = "修改商机"

      businessList({id: row.id}).then(res=>{
        this.ruleForm.id = res.data.id





        this.ruleForm.uid = res.data.uid.toString()
        this.ruleForm.title = res.data.title
        this.ruleForm.expect_amount = res.data.expect_amount
        this.ruleForm.customer_name = res.data.customer_name
        this.ruleForm.customer_contact_info = res.data.customer_contact_info
        this.ruleForm.expect_start_time = res.data.expect_start_time*1000
        this.ruleForm.expect_finish_time = res.data.expect_finish_time*1000
        this.ruleForm.expect_delay_time = res.data.expect_delay_time == 0?'':res.data.expect_delay_time*1000
        this.ruleForm.project_contacts = res.data.project_contacts
        this.ruleForm.project_description = res.data.project_description
        this.ruleForm.task = res.data.ren_wu_lie_biao
        for (let i = 0; i < this.ruleForm.task.length; i++) {
          this.ruleForm.task[i].uid = this.ruleForm.task[i].uid.toString()
          this.ruleForm.task[i].expect_start_time = this.ruleForm.task[i].expect_start_time*1000
          this.ruleForm.task[i].expect_finish_time = this.ruleForm.task[i].expect_finish_time*1000
          delete this.ruleForm.task[i].ze_ren_ren
        }
        this.ruleForm.totalDay = (this.ruleForm.expect_finish_time - this.ruleForm.expect_start_time)/(24*60*60*1000).toFixed(0)

        console.log(this.ruleForm)
        this.sjData.isDialog = true
      })
    },
    //table 详情 按钮点击
    detailBusiness(row) {
      this.ruleForm = this.$options.data().ruleForm
      this.taskTableDetailProp = this.$options.data().taskTableDetailProp
      this.sjData.type = 4
      this.sjData.title = "商机详情"

      businessList({id: row.id}).then(res=>{
        this.ruleForm.id = res.data.id
        this.ruleForm.uid = res.data.uid.toString()
        this.ruleForm.title = res.data.title
        this.ruleForm.expect_amount = res.data.expect_amount
        this.ruleForm.customer_name = res.data.customer_name
        this.ruleForm.customer_contact_info = res.data.customer_contact_info
        this.ruleForm.expect_start_time = res.data.expect_start_time*1000
        this.ruleForm.expect_finish_time = res.data.expect_finish_time*1000
        this.ruleForm.expect_delay_time = res.data.expect_delay_time*1000
        this.ruleForm.project_contacts = res.data.project_contacts
        this.ruleForm.project_description = res.data.project_description
        this.ruleForm.task = res.data.ren_wu_lie_biao
        for (let i = 0; i < this.ruleForm.task.length; i++) {
          switch (this.ruleForm.task[i].task_status) {
            case 1:
              this.ruleForm.task[i].nowStatus = '未开始'
              break;
            case 2:
              this.ruleForm.task[i].nowStatus = '已开始'
              break;
            case 3:
              this.ruleForm.task[i].nowStatus = '完成'
              break;
          }
          this.ruleForm.task[i].expect_days = ((this.ruleForm.task[i].expect_finish_time - this.ruleForm.task[i].expect_start_time)/(24*60*60)).toFixed(0)
          if (this.ruleForm.task[i].actual_finish_time && this.ruleForm.task[i].actual_start_time){
            this.ruleForm.task[i].actual_day = ((this.ruleForm.task[i].actual_finish_time - this.ruleForm.task[i].actual_start_time)/(24*60*60)).toFixed(0)
          }
          this.ruleForm.task[i].btn = this.ruleForm.task[i].my_btn.toString() + this.ruleForm.task[i].task_status.toString()
        }
        this.ruleForm.totalDay = Math.trunc((this.ruleForm.expect_finish_time - this.ruleForm.expect_start_time)/(24*60*60*1000))
        if (new Date().getTime()>=this.ruleForm.expect_finish_time){
          this.ruleForm.timeoutDay = Math.trunc((new Date().getTime() - this.ruleForm.expect_finish_time)/(24*60*60*1000))
        } else {
          this.ruleForm.timeoutDay = 0
        }
        this.ruleForm.shang_ji_ji_lu = res.data.shang_ji_ji_lu
        //判断商机状态是否为待立项，控制任务操作显示
        if (res.data.status !== 1){
          this.taskTableDetailProp.table.splice(10,1)
        }

        console.log(this.ruleForm)
        this.sjData.isDialog = true
      })
    },
    //商机任务开始结束操作
    starTask(row){
      this.$confirm('此操作为开始任务，是否继续？', '开始任务', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row)
        taskOperation({id: row.id, task_status: 2}).then(()=>{
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.sjData.isDialog = false
          this.initLoad()
        })
      })
    },
    endTask(row){
      this.$confirm('此操作为完成任务，是否继续？', '完成任务', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row)
        taskOperation({id: row.id, task_status: 3}).then(()=>{
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.sjData.isDialog = false
          this.initLoad()
        })
      })
    },
    //table 删除 按钮点击
    deleteBussiness(row) {
      this.$confirm('此操作为删除商机，是否继续？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row)
        businessDel({id: row.id}).then(()=>{
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.initLoad()
        })
      })

    },

    //table 立项 按钮点击
    approvalProject(row) {
      console.log(row)
      this.$confirm('此操作为立项操作，将跳转新建项目页面，是否继续？', '立项', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row)
        window.localStorage.setItem('business', JSON.stringify(row))
        this.$router.push({path:'./new_project'})
      })
    },

    //table 搁置 按钮点击
    layUpproject(row) {
      this.businessOperationData = this.$options.data().businessOperationData
      this.businessOperationData.id = row.id
      this.businessOperationData.record_type = 2
      this.layUpdialogvisible = true
    },
    subLayup(){
      if (!this.businessOperationData.operate_time){
        this.$confirm("*为必填项信息，请填写完整后提交！", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        return false
      }
      this.layUpdialogvisible = false
      this.businessOperationData.operate_time = this.businessOperationData.operate_time/1000
      businessOperation(this.businessOperationData).then(()=>{
        this.$message({
          message: '操作成功！',
          type: 'success'
        })
        this.initLoad()
      })
    },

    //table 还原 按钮点击
    reductionProject(row) {
      this.businessOperationData = this.$options.data().businessOperationData
      this.businessOperationData.id = row.id
      this.businessOperationData.record_type = 1
      this.reductionVisible = true
    },
    subReduction(){
      if (!this.businessOperationData.operate_time){
        this.$confirm("*为必填项信息，请填写完整后提交！", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        return false
      }
      this.reductionVisible = false
      this.businessOperationData.operate_time = this.businessOperationData.operate_time/1000
      businessOperation(this.businessOperationData).then(()=>{
        this.$message({
          message: '操作成功！',
          type: 'success'
        })
        this.initLoad()
      })
    },

    //table 分页 点击当前页
    pageCurrentChange(val) {

      this.tableProp.page.current_page = val
      this.initLoad();
    },
    handleSizeChange(val) {
      this.tableProp.page.size = val
      this.initLoad();
    },

    //格式判断
    expect_finish_timeChange(row){
      if (row.expect_start_time && row.expect_finish_time){
        if (row.expect_finish_time < row.expect_start_time){
          this.$confirm('结束时间不得早于开始时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            row.expect_finish_time = ''
          })
          .catch(() => {
            row.expect_finish_time = ''
          })
        }
      }
    },
    expect_start_timeChange(row){
      if (row.expect_start_time && row.expect_finish_time){
        if (row.expect_finish_time < row.expect_start_time){
          this.$confirm('结束时间不得早于开始时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            row.expect_start_time = ''
          })
          .catch(() => {
            row.expect_start_time = ''
          })
        }
      }
    },
    getDay(type){
      console.log(this.ruleForm)
      //统一时间合理性判断
      if (this.ruleForm.expect_finish_time && this.ruleForm.expect_start_time && this.ruleForm.expect_finish_time < this.ruleForm.expect_start_time){
        this.$confirm('结束时间必须大于开始时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (type == 'expect_start_time'){
            this.ruleForm.expect_start_time = ''
          }
          if (type == 'expect_finish_time'){
            this.ruleForm.expect_finish_time = ''
          }
          this.ruleForm.totalDay = ''
        })
        .catch(()=>{
          if (type == 'expect_start_time'){
            this.ruleForm.expect_start_time = ''
          }
          if (type == 'expect_finish_time'){
            this.ruleForm.expect_finish_time = ''
          }
          this.ruleForm.totalDay = ''
        })
      }
      if (this.ruleForm.expect_finish_time && this.ruleForm.expect_delay_time && this.ruleForm.expect_delay_time < this.ruleForm.expect_finish_time){
        this.$confirm('延迟时间必须大于或等于结束时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (type == 'expect_delay_time'){
            this.ruleForm.expect_delay_time = ''
          }
          if (type == 'expect_finish_time'){
            this.ruleForm.expect_finish_time = ''
          }
        })
        .catch(()=>{
          if (type == 'expect_delay_time'){
            this.ruleForm.expect_delay_time = ''
          }
          if (type == 'expect_finish_time'){
            this.ruleForm.expect_finish_time = ''
          }
        })
      }
      //计算总天数
      if (this.ruleForm.expect_finish_time && this.ruleForm.expect_start_time){
        console.log("计算总天数")
        this.ruleForm.totalDay = Math.trunc((this.ruleForm.expect_finish_time - this.ruleForm.expect_start_time)/(24*60*60*1000))
      }
    },

    //商机任务相关
    // 添加任务 按钮点击
    addTask() {
      let item = {
        uid: '',
        task_title: '',
        expect_start_time: '',
        expect_finish_time:'',
        achievements: "",
      }
      this.ruleForm.task.push(item)
    },
    //删除任务
    deleteTask(row, index) {
      console.log(row, index)
      this.ruleForm.task.splice(index, 1)
    },

  },
};
</script>

<style>
.custom-tree-container{
  width: 300px;
}
.custom-tree-node{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>

<style lang="scss" scoped>
.cdul{
  width: 90%;
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 130px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }


  }
}
.common-add-dialog {
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 130px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }

  }
}


</style>
