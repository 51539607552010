<template>
  <!--
  rules 验证
  -->
  <div>
    <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="170px"
        style="width: 90%">
      <el-row>

        <!--prop 验证 -->
        <el-col :span="24">
          <div style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">基本信息</div>
        </el-col>
        <el-col :span="11">
          <el-form-item label="是否为子项目" prop="" class="required">
            <!--            <el-switch v-model="ruleForm.is_external"></el-switch>-->
            <el-radio-group v-model="ruleForm.is_son" @input="checkIsSon">
              <el-radio label="2">否</el-radio>
              <el-radio label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="11" v-if="ruleForm.is_son == 1">
          <el-form-item label="所属项目" prop="" class="required">
            <el-select v-model="ruleForm.pid" placeholder="请选择所属项目" value-key="project_title" @change="setProjectType" style="width: 100%">
              <el-option
                  v-for="(itemOption,keyOption) in pidOption"
                  :key="keyOption"
                  :label="itemOption.project_title"
                  :value="itemOption.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

  <!--      <el-col :span="11">
          <el-form-item label="项目集" prop="" class="required">
            <el-select v-model="ruleForm.pid" placeholder="请选择项目类型" @change="setProjectType" style="width: 100%">
              <el-option
                  v-for="(itemOption,keyOption) in set_idOption"
                  :key="keyOption"
                  :label="itemOption.items_title"
                  :value="itemOption.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>-->

        <el-col :span="11">
          <el-form-item label="项目类型" prop="" class="required">
            <el-select v-model="ruleForm.type_id" placeholder="请选择项目类型" style="width: 100%" :disabled="ruleForm.is_son == 1?true:false">
              <el-option
                  v-for="(itemOption,keyOption) in typeIdOptions"
                  :key="keyOption"
                  :label="itemOption.label"
                  :value="itemOption.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="项目名称" prop="project_title" class="required">
            <el-input v-model="ruleForm.project_title" placeholder="请输入项目名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="预计项目金额(万元)" prop="" class="required">
            <el-input v-model="ruleForm.all_price" placeholder="请输入预计项目金额"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="计划成单时间" class="required">
            <el-form-item prop="start_time">
              <el-date-picker type="date" placeholder="请选择日期" v-model="ruleForm.start_time" value-format="timestamp"
                              style="width: 100%;" @change="getCycleTime('start_time')"></el-date-picker>
            </el-form-item>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="计划交付时间" class="required">
            <el-form-item prop="end_time">
              <el-date-picker type="date" placeholder="请选择日期" v-model="ruleForm.end_time" value-format="timestamp"
                              @change="getCycleTime('end_time')"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="计划项目周期(天)" prop="">
            <el-input v-model="ruleForm.cycle_time" @change="setEndTime()"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item label="保密等级" prop="" class="required">
            <el-select v-model="ruleForm.security_level" placeholder="请选择保密等级" @change="setText" style="width: 39%">
              <el-option label="一级" value="1"></el-option>
              <el-option label="二级" value="2"></el-option>
              <el-option label="三级" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-bottom: 0px">
            {{levelText}}
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">客户信息</div>
        </el-col>

        <el-col :span="11">
          <el-form-item label="客户名称" prop="">
          <el-input v-model="ruleForm.customer_company" placeholder="请输入客户名称"></el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="项目联系人" prop="">
            <el-input v-model="ruleForm.customer" placeholder="请输入客户名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="联系方式" prop="">
            <el-input v-model="ruleForm.customer_tel" placeholder="请输入联系方式"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <div style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">商务立项信息</div>
        </el-col>
        <el-col :span="11">
          <el-form-item label="项目来源" prop="">
            <el-select v-model="ruleForm.source_from" placeholder="请选择项目来源" style="width: 100%">
              <el-option label="朋友介绍" value="朋友介绍"></el-option>
              <el-option label="客户推荐" value="客户推荐"></el-option>
              <el-option label="内部拓展" value="内部拓展"></el-option>
              <el-option label="政府引导" value="政府引导"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="预计成单几率(%)" prop="">
            <el-input v-model="ruleForm.probability" placeholder="请输入预计成单几率"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="期望分红比例(%)" prop="" class="required">
            <el-input v-model="ruleForm.dividend" placeholder="请输入期望分红比例"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="期望成长值" prop="" class="required">
            <el-input v-model="ruleForm.hope_growth_value" placeholder="请输入期望成长值"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item label="项目承诺" prop="">
            <el-input type="textarea" v-model="ruleForm.commitment" placeholder="请输入项目承诺"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item label="项目奖惩期望" prop="">
            <el-input type="textarea" v-model="ruleForm.reward_punishment" placeholder="请输入项目奖惩机制"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item label="商务备注" prop="">
            <el-input type="textarea" v-model="ruleForm.remark" placeholder="请输入商务备注"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">成本预算</div>
        </el-col>

        <el-col :span="11">
          <el-form-item label="居间成本" prop="" class="required">
            <el-input v-model="ruleForm.source_cost" placeholder="请输入居间成本">
              <el-select v-model="ruleForm.source_cost_is_rate" slot="prepend" placeholder="请选择" style="width: 80px">
                <el-option label="比例" value="1"></el-option>
                <el-option label="金额" value="0"></el-option>
              </el-select>
              <template slot="append" v-if="ruleForm.source_cost_is_rate == 1">%</template>
              <template slot="append" v-else>万元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="市场及商务投入成本(%)" prop="" class="required">
            <el-input v-model="ruleForm.market_cost" placeholder="请输入市场及商务投入成本"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="是否外部协作" prop="" class="required">
            <!--            <el-switch v-model="ruleForm.is_external"></el-switch>-->
            <el-radio-group v-model="ruleForm.is_external">
              <el-radio label="0">否</el-radio>
              <el-radio label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="11" v-if="ruleForm.is_external == 1">
          <el-form-item label="外部协作成本" prop="" class="required">
            <el-input v-model="ruleForm.external_cost" placeholder="请输入外部协作成本">
              <el-select v-model="ruleForm.external_cost_is_rate" slot="prepend" placeholder="请选择" style="width: 80px">
                <el-option label="比例" value="1"></el-option>
                <el-option label="金额" value="0"></el-option>
              </el-select>
              <template slot="append" v-if="ruleForm.external_cost_is_rate == 1">%</template>
              <template slot="append" v-else>万元</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <div style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">市场成员明细</div>
        </el-col>
        <el-col :span="22">
          <el-form-item label="" prop="">
            <el-button type="primary" @click="addMember">添加成员</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item label="" prop="">
            <common-table
                :tableData="ruleForm.market_member_json"
                :tableProp="tableProp"
                @role_idDialogChange = "checkRole"
                @department_nameDialogChange = "getPost"
                @post_nameDialogChange = "getUser"
                @user_idDialogChange = "getUserName"
                @deleteMember = "deleteMember"
                @start_timeChange = "start_timeChange"
                @end_timeChange = "end_timeChange"
                @join_rateChange = "join_rateChange"
            />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <div style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">里程碑信息</div>
        </el-col>
        <el-col :span="22">
          <el-form-item label="" prop="">
            <el-button type="primary" @click="addMilepost">添加里程碑</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item label="" prop="">
            <common-table
                :tableData="ruleForm.milepost"
                :tableProp="milepostTableProp"
                @deleteMilepost = "deleteMilepost"
            />
          </el-form-item>
        </el-col>


        <el-col :span="24">
          <div style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">立项文件信息</div>
        </el-col>

        <el-col :span="22">
          <el-form-item label="附件上传" prop="01">
            <common-new-file :uploadFileData="ruleForm.attachment_json" :member="sc_member"/>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <common-shenp :shenP="shenP"></common-shenp>
        </el-col>
        <el-col :span="22">
          <el-form-item style="margin-top: 20px;">
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>


</template>
<script>
import {timer} from '@/assets/js/comment';
import {
  addProject, editProject,
  getDepartment, getParentPro,
  getPost, getProjectDetail, getProjectType,
  getUser
} from '@/api/project_management/new_project'
import {businessOperation} from "@/api/project_management/business_management";


export default {
  components: {},
  data() {
    return {
      children_project_count: 0,
      businessdata: {},
      ifBusiness: 0,
      levelText: '',
      ruleForm: {
        source_cost_is_rate: '1',
        external_cost_is_rate: '1',
        is_son:'2',
        project_title: '',
        pid: '',
        type_id: '',
        all_price: '',
        start_time: '',
        end_time: '',
        cycle_time: '',
        customer_company:'',
        customer: '',
        customer_tel: '',
        security_level: '',
        source_from: '',
        probability:'',
        dividend: '',
        hope_growth_value: '',
        commitment: '',
        reward_punishment: '',
        remark: '',
        source_cost: '',
        market_cost: '',
        is_external: '1',
        external_cost: '0',
        attachment_json: [],
        market_member_json:[{role_id: 1, user_id:'', department_name: '', post_name: '', start_time:"",end_time:"",task_name:'',join_rate:'', achievements:'', post_nameProp: [], user_idProp: [],}],
        milepost: [],
        audit_json: {},
      },
      sc_member:[],
      typeIdOptions: [],
      pidOption: [],
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '项目角色',
          field: 'role_id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 8,
          labelClassName: 'required3'
        }, {
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 8,
          labelClassName: 'required3'
        }, {
          title: '岗位',
          field: 'post_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
          labelClassName: 'required3'
        }, {
          title: '姓名',
          field: 'user_id',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
          labelClassName: 'required3'
        }, {
          title: '计划开始时间',
          field: 'start_time',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
          labelClassName: 'required3'
        }, {
          title: '计划结束时间',
          field: 'end_time',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
          labelClassName: 'required3'
        }, {
          title: '任务名称',
          field: 'task_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '计划参与度(%)',
          field: 'join_rate',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '任务绩效',
          field: 'achievements',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '删除',
            type: 'danger',
            method: 'deleteMember',
            status: '20000',
            id: 20000,
          }],

        }],
        department_nameProp: [],
        role_idProp: [
          {value: 1, label: '项目经理'},
          {value: 2, label: '市场'},
          {value: 3, label: '商务'},
          {value: 4, label: '行政'},
          {value: 5, label: '产品'},
          {value: 6, label: '其他'}
        ],
      },
      //里程碑
      milepostTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '里程碑名称',
          field: 'title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '完成时间',
          field: 'finish_time',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
          labelClassName: 'required3'
        }, {
          title: '资源需求',
          field: 'source_required',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '删除',
            type: 'danger',
            method: 'deleteMilepost',
            status: '20000',
            id: 20000,
          }],
        }],
      },

      shenP:{
        labelWidth: "160px",
        shenPr:[],
        type: 1,
        titleType: 1, 
        shenpm: '',
        data:[],
      },
    };
  },
  created() {
    this.getDepartment()
    this.getProjectType()
    this.business()
    this.getParentPro()
    if(this.$route.query.id){
      this.init()
    }
    if (!this.$route.query.id){
      this.ruleForm.market_member_json[0].department_name = Number(localStorage.getItem("departmentId"))
      this.getPost(this.ruleForm.market_member_json[0])
      this.ruleForm.market_member_json[0].post_name = Number(localStorage.getItem("postId"))
      this.getUser(this.ruleForm.market_member_json[0])
      this.ruleForm.market_member_json[0].user_id = Number(localStorage.getItem("userId"))
    }
  },
  mounted() {
    this.$store.state.loading = false;
  },
  methods: {
    //商机信息填充
    business(){
      this.businessdata = JSON.parse(window.localStorage.getItem('business'))
      if (this.businessdata){
        this.ruleForm.project_title = this.businessdata.title
        this.ruleForm.all_price = this.businessdata.expect_amount
        this.ruleForm.customer_company = this.businessdata.customer_name
        this.ruleForm.customer = this.businessdata.project_contacts
        this.ruleForm.customer_tel = this.businessdata.customer_contact_info
        this.ifBusiness = 1
        window.localStorage.removeItem('business')
      }
    },
    init(){
      getProjectDetail({id: this.$route.query.id}).then((result)=>{
        let data = {...Object.assign(result.data, result.data.project_price, result.data.project_market)}
        console.log(data)
        this.children_project_count = data.children_project_count

        this.ruleForm.is_son = data.is_son+''
        this.ruleForm.project_title = data.project_title
        this.ruleForm.pid = data.pid==0?'':data.pid
        this.ruleForm.type_id = data.type_id+''

        this.ruleForm.all_price = data.all_price/10000
        this.ruleForm.start_time = data.start_time*1000
        this.ruleForm.end_time = data.end_time*1000
        this.ruleForm.cycle_time = data.cycle_time
        this.ruleForm.customer_company = data.customer_company
        this.ruleForm.customer = data.customer
        this.ruleForm.customer_tel = data.customer_tel
        this.ruleForm.security_level = data.security_level+''
        this.ruleForm.source_from = data.source_from
        this.ruleForm.probability = data.probability
        this.ruleForm.dividend = data.dividend
        this.ruleForm.hope_growth_value = data.hope_growth_value
        this.ruleForm.commitment = data.commitment
        this.ruleForm.reward_punishment = data.reward_punishment
        this.ruleForm.remark = data.remark
        this.ruleForm.source_cost_is_rate = data.source_cost_is_rate+''
        this.ruleForm.external_cost_is_rate = data.external_cost_is_rate+''
        this.ruleForm.source_cost = data.source_cost
        this.ruleForm.market_cost = data.market_cost
        this.ruleForm.is_external = data.is_external+''
        this.ruleForm.external_cost = data.external_cost
        this.ruleForm.remark = data.remark
        if (data.attachment_json){
          this.ruleForm.attachment_json = JSON.parse(data.attachment_json)
        } else {
          this.ruleForm.attachment_json = []
        }
        if (data.market_member_json){
          this.ruleForm.market_member_json = JSON.parse(data.market_member_json)
          for (let i = 0; i< this.ruleForm.market_member_json.length; i++){
            if (this.ruleForm.market_member_json[i]){
              this.ruleForm.market_member_json[i].start_time = this.ruleForm.market_member_json[i].start_time*1000
              this.ruleForm.market_member_json[i].end_time = this.ruleForm.market_member_json[i].end_time*1000
              let data = {
                user_id: this.ruleForm.market_member_json[i].user_id,
                user_name: ''
              }
              for (let a = 0; a<this.ruleForm.market_member_json[i].user_idProp.length; a++){
                if (this.ruleForm.market_member_json[i].user_idProp[a] && this.ruleForm.market_member_json[i].user_idProp[a].user_id == this.ruleForm.market_member_json[i].user_id){
                  data.user_name = this.ruleForm.market_member_json[i].user_idProp[a].name
                }
              }
              this.sc_member.splice(i,1,data)
            }
          }
        } else {
          this.ruleForm.market_member_json = [{role_id: '', user_id:'', department_name: '', post_name: '', start_time:"",end_time:"",task_name:'',join_rate:'',achievements: '', post_nameProp: [], user_idProp: [],}]
        }
        if (data.li_cheng_bei){
          this.ruleForm.milepost = data.li_cheng_bei
          for (let i = 0; i < this.ruleForm.milepost.length; i++) {
            if (this.ruleForm.milepost[i]){
              this.ruleForm.milepost[i].finish_time = this.ruleForm.milepost[i].finish_time * 1000
            }
          }
        } else {
          this.ruleForm.milepost = []
        }
        this.shenP.data = JSON.parse(data.audit_json)

        console.log(this.ruleForm)
      })
    },
    //计算计划周期与交付时间
    getCycleTime(val) {
      if (this.ruleForm.end_time && this.ruleForm.start_time){
        this.ruleForm.cycle_time = timer(this.ruleForm.end_time - this.ruleForm.start_time)
        this.ruleForm.market_member_json[0].start_time = this.ruleForm.start_time
        this.ruleForm.market_member_json[0].end_time = this.ruleForm.end_time
        if (this.ruleForm.end_time < this.ruleForm.start_time){
          this.$confirm('结束时间必须大于开始时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (val == 'start_time'){
              this.ruleForm.start_time = ''
            }
            if (val == 'end_time'){
              this.ruleForm.end_time = ''
            }
          })
          .catch(() => {
            if (val == 'start_time'){
              this.ruleForm.start_time = ''
            }
            if (val == 'end_time'){
              this.ruleForm.end_time = ''
            }
          })
        }
      }
      if (val == 'start_time'){
        if (this.ruleForm.cycle_time && this.ruleForm.start_time){
          this.ruleForm.end_time = this.ruleForm.start_time + Number(this.ruleForm.cycle_time)*24*60*60*1000
        }
      }
    },
    setEndTime(){
      //判断输入是否为大于0的整数
      if(!Number(this.ruleForm.cycle_time) || Number(this.ruleForm.cycle_time) !== Number(Number(this.ruleForm.cycle_time).toFixed(0)) || Number(this.ruleForm.cycle_time) < 0){
        this.$confirm('计划项目周期必须为大于0的整数！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.ruleForm.cycle_time = ""
        })
        return false
      }
      if (this.ruleForm.start_time){
        this.ruleForm.end_time = this.ruleForm.start_time + Number(this.ruleForm.cycle_time)*24*60*60*1000
        this.ruleForm.market_member_json[0].start_time = this.ruleForm.start_time
        this.ruleForm.market_member_json[0].end_time = this.ruleForm.end_time
      }
    },
    //是否子项目改变检查
    checkIsSon(val){
      if (this.children_project_count !== 0 && val == 1){
        this.$confirm('该项目有关联的子项目，无法修改为子项目！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        this.ruleForm.is_son = "2"
      }
      if (val == 2){
        this.ruleForm.pid = ''
      }
    },
    //提交表单
    submitForm() {
      console.log(this.ruleForm)
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = JSON.parse(JSON.stringify(this.ruleForm))
        console.log(this.shenP.data)

        //审批人判断
        if (this.shenP.data.length < 1){
          this.$confirm('至少选择一个审批人！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        } else {
          for (let i = 0; i < this.shenP.data.length; i++) {
            if (!this.shenP.data[i].user_id){
              this.$confirm('审批人信息填写不完整！', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {})
              return false
            }
          }
        }
        //信息完整性判断
        if (!subData.is_son || !subData.type_id || !subData.project_title || !subData.all_price || !subData.start_time || !subData.end_time ||
            !subData.security_level || !subData.dividend || !subData.hope_growth_value || !subData.source_cost || !subData.market_cost){
          this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
        //项目为子项目时判断
        if (subData.is_son == 1 && !subData.pid){
          this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
        //外部协作成本判断
        if (subData.is_external == 1){
          if (!subData.external_cost){
            this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {})
            return false
          }
        }
        //里程碑判断
        if (subData.milepost.length == 0){
          this.$confirm('里程碑至少填写一个！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
        for (let i = 0; i < subData.milepost.length; i++) {
          if (!subData.milepost[i].title || !subData.milepost[i].finish_time || !subData.milepost[i].source_required){
            this.$confirm('里程碑信息填写不完整！', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {})
            return false
          }
          subData.milepost[i].finish_time = subData.milepost[i].finish_time/1000
        }
        //市场成员计划参与度判断
        let sumJoin_rate = 0
        //市场成员信息完整性
        for (let i = 0; i<subData.market_member_json.length; i++ ){
          if (!subData.market_member_json[i].start_time || !subData.market_member_json[i].end_time || !subData.market_member_json[i].task_name ||
              !subData.market_member_json[i].user_id || !subData.market_member_json[i].join_rate ){
            this.$confirm('市场成员信息填写不完整！', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {})
            return false
          }
          subData.market_member_json[i].start_time = subData.market_member_json[i].start_time/1000
          subData.market_member_json[i].end_time = subData.market_member_json[i].end_time/1000
          sumJoin_rate = sumJoin_rate + parseFloat(subData.market_member_json[i].join_rate)
        }
        if (sumJoin_rate !== 100){
          this.$confirm('市场成员计划参与度加和必须等于一百！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
        if (subData.is_external == 0){
          subData.external_cost = 0
        }
        //项目角色判断
        let isRole = true
        for (let i = 0; i < subData.market_member_json.length; i++) {
          switch (subData.market_member_json[i].role_id) {
            case 1:
              subData.market_member_json[i].role_name = '项目经理';
              subData.manager_id = subData.market_member_json[i].user_id;
              isRole = false
              break;
            case 2:
              subData.market_member_json[i].role_name = '市场';
              break;
            case 3:
              subData.market_member_json[i].role_name = '商务';
              break;
            case 4:
              subData.market_member_json[i].role_name = '行政';
              break;
            case 5:
              subData.market_member_json[i].role_name = '产品';
              break;
            case 6:
              subData.market_member_json[i].role_name = '其他';
              break;
          }
        }
        if (isRole){
          this.$confirm('市场成员需要有项目经理！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }

        subData.start_time = subData.start_time / 1000;
        subData.end_time = subData.end_time / 1000;
        subData.attachment_json = JSON.stringify(subData.attachment_json);
        subData.audit_json = JSON.stringify(this.shenP.data)
        subData.market_member_json = JSON.stringify(subData.market_member_json);
        subData.milepost = JSON.stringify(subData.milepost);
        if(this.$route.query.id){
          console.log(subData)
          subData.id = this.$route.query.id
          editProject(subData).then((result) => {
            this.$message({
              message: result.msg,
              type: 'success'
            });
            this.$router.push({path:'./project_list'})
          })
        } else {
          addProject(subData).then((result) => {
            this.$message({
              message: result.msg,
              type: 'success'
            });
            if (this.ifBusiness == 1){
              let businessData = {}
              businessData.record_type = 3
              businessData.operate_time = Math.trunc(new Date().getTime()/1000)
              businessData.id = this.businessdata.id
              businessOperation(businessData).then(()=>{})
            }
            this.$router.push({path:'./project_list'})
          })
        }
      })


    },
    //重置表单
    resetForm() {
      this.ruleForm = this.$options.data().ruleForm
      this.shenP = this.$options.data().shenP
    },
    // 添加市场成员 按钮点击
    addMember() {
      let newMember = {role_id:'', user_id:'', department_name: '', post_name: '', start_time:"",end_time:"",task_name:'',join_rate:'',achievements:'', post_nameProp: [], user_idProp: []}
      newMember.start_time = this.ruleForm.start_time
      newMember.end_time = this.ruleForm.end_time
      this.ruleForm.market_member_json.push(newMember)
    },
    // 删除市场成员 按钮点击
    deleteMember(row, index) {
      this.ruleForm.market_member_json.splice(index, 1)
      this.sc_member.splice(index, 1)

      for (let i = 0; i<this.ruleForm.attachment_json.length; i++){
        for (let a = 0; a<this.ruleForm.attachment_json[i].power_member.length; a++){
          let aaa = this.ruleForm.attachment_json[i].power_member[a]
          if(aaa == row.user_id){
            this.ruleForm.attachment_json[i].power_member.splice(a, 1)
          }
        }
      }
    },
    //市场成员起止时间
    start_timeChange(row){
      /*if (row.start_time < new Date().getTime()-86400000 && row.start_time){
        this.$confirm('开始时间不得早于当前时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.start_time = ''
        })
            .catch(() => {
              row.start_time = ''
            })
      }*/
      if (row.start_time > this.ruleForm.end_time && row.start_time){
        this.$confirm('开始时间不得晚于计划交付时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.start_time = ''
        }).catch(() => {
          row.start_time = ''
        })
      }
      if (row.end_time && row.start_time){
        if (row.end_time < row.start_time){
          this.$confirm('开始时间不得晚于结束时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            row.start_time = ''
          })
              .catch(() => {
            row.start_time = ''
          })
        }
      }
    },
    end_timeChange(row){
      /*if (row.end_time < new Date().getTime()-86400000 && row.end_time){
        this.$confirm('结束时间不得早于当前时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.end_time = ''
        })
            .catch(() => {
              row.end_time = ''
            })
      }*/
      if (row.end_time > this.ruleForm.end_time && row.end_time){
        this.$confirm('结束时间不得晚于项目计划交付时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.end_time = ''
        })
            .catch(() => {
              row.end_time = ''
            })
      }
      if (row.start_time && row.end_time){
        if (row.end_time < row.start_time){
          this.$confirm('结束时间不得早于开始时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            row.end_time = ''
          })
              .catch(() => {
            row.end_time = ''
          })
        }
      }
    },
    join_rateChange(row){
      let reg = /^(0|[1-9]\d*)(\.\d{1,1})?/
      let res = row.join_rate.match(reg)
      if (res[0] !==res.input ){
        this.$message({
          message: '最多保留一位小数！',
          type: 'error'
        });
        row.join_rate = res[0]
      }
    },

    //添加里程碑
    addMilepost(){
      let newMilepost = {title:'', finish_time:'', source_required:''}
      this.ruleForm.milepost.push(newMilepost)
    },
    //删除里程碑
    deleteMilepost(row, index){
      this.ruleForm.milepost.splice(index, 1)
    },
    //里程碑时间判断
    finish_timeChange(row){
      if (row.finish_time > this.ruleForm.end_time && row.finish_time){
        this.$confirm('结束时间不得晚于计划交付时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.finish_time = ''
        }).catch(() => {
          row.finish_time = ''
        })
      }
      if (row.finish_time < this.ruleForm.start_time && row.finish_time){
        this.$confirm('结束时间不得早于计划成单时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.finish_time = ''
        }).catch(() => {
          row.finish_time = ''
        })
      }
    },

    // addDialog 点击确定
    addDialogSub() {
      this.subData = {...this.addDialogData.addDialogShowData}  //ES6深拷贝
      this.subData.start_time = this.subData.start_time / 1000;
      this.subData.end_time = this.subData.end_time / 1000;
      this.subData.attachment_json = JSON.stringify(this.subData.attachment_json);

      // editProject(this.subData).then((result) => {
      //   this.addDialogData.isDialog = false;
      //   this.$message({
      //     message: result.msg,
      //     type: 'success'
      //   });
      // })
      //     .catch((error) => {
      //       this.$message({
      //         message: error.msg,
      //         type: 'error'
      //       });
      //     })
    },
    //获取部门
    getDepartment(){
      getDepartment().then((result) => {
        for (let i = 0; i < result.data.length; i++){
          result.data[i].value = result.data[i].id
          result.data[i].label = result.data[i].department_name
        }
        this.tableProp.department_nameProp = result.data
      })
    },
    //获取岗位
    getPost(val){
      val.post_name = ''
      val.user_id = ''
      val.post_nameProp = []
      val.user_idProp = []
      let params = {pid : val.department_name}
      getPost(params).then((result) => {
        for (let i = 0; i < result.data.length; i++){
          result.data[i].value = result.data[i].id
          result.data[i].label = result.data[i].department_name
        }
        val.post_nameProp = result.data
      })
    },
    //获取人员
    getUser(val){
      val.user_id = ''
      val.user_idProp = []
      let params = {department_id : val.post_name}
      getUser(params).then((result) => {
        for (let i = 0; i < result.data.length; i++){
          result.data[i].value = result.data[i].user_id
          result.data[i].label = result.data[i].name
        }
        val.user_idProp = result.data
        if (!this.$route.query.id){
          this.getUserName(this.ruleForm.market_member_json[0], 0)
        }
      })
    },
    //获取人员姓名
    getUserName(val, key){
      let data = {
        user_id: val.user_id,
        user_name: ''
      }
      for (let i = 0; i<val.user_idProp.length; i++){
        if (val.user_idProp[i].user_id == val.user_id){
          data.user_name = val.user_idProp[i].name
        }
      }
      this.sc_member.splice(key,1,data)
      // this.member.push(data)
    },
    //获取项目类型
    getProjectType(){
      getProjectType().then(res=>{
        this.typeIdOptions=res.data.map(item => ({
          label: item.type_name,
          value: (item.id).toString()
        }))
      })
    },
  //  设置项目类型
    setProjectType(val){
      this.pidOption.map(item=>{
        if (item.id == val){
          this.ruleForm.type_id = item.type_id.toString()
        }
      })
    },
  //  设置保密文本
    setText(){
      if (this.ruleForm.security_level == 1){
        this.levelText = '保密要求：一般保密，不得透露给公司以外人员任何项目信息；妥善保管相关文件资料，项目完成后全部归档处理。'
      } else if (this.ruleForm.security_level == 2){
        this.levelText = '保密要求：重要保密，不得透露给公司以外人员；不得带出公司；同事之间不得相互交流；只能向指定项目人员发送；除项目需要不得将存有相关资料的U盘等存储设备带离公司；妥善保管相关文件资料，项目完成后全部归档处理。'
      } else if (this.ruleForm.security_level == 3){
        this.levelText = '保密要求：重点保密，不得透露给项目以外人员；项目周期以外资料须销毁；不得带出公司；同事之间不得相互交流；只能向指定项目人员发送；未经允许不得拷贝到U盘，发送到微信、邮件等公共网络上。'
      }
    },
    //项目角色选择判断项目经理是否唯一
    checkRole(row){
      let num = 0
      for (let i = 0; i < this.ruleForm.market_member_json.length; i++) {
        if (this.ruleForm.market_member_json[i].role_id == 1){
          num = num + 1
        }
      }
      if (num !== 1){
        this.$confirm('项目经理必须有且只能有一个！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (num == 0){
          row.role_id = 1
        } else {
          row.role_id = ''
        }
      }
    },
  //  获取父级项目
    getParentPro(){
      getParentPro().then((res)=>{
          console.log(res)
        this.pidOption=res.data
      })
    }
  },

}
</script>
<style>
.el-form > .el-form-item {
  width: 90%;
}

</style>
