<template>
  <!--
  rules 验证
  -->

  <div>

    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
    />


    <div v-if="pageInfo=='shenp'">
      <el-row v-for="(item,index) in auditRecord" :key="index">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" label-width="120px">
              <el-form-item label="提交人">
                {{ item.user_name }}
              </el-form-item>
              <el-form-item label="审批类型">
                <span v-if="item.type==0||item.type==1">新增/修改</span>
                <span v-else>成单</span>
              </el-form-item>
              <el-form-item label="审批人">
                {{ item.approver_user_name }}
              </el-form-item>
              <el-form-item label="审批意见">
                {{ item.opinion }}
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" label-width="120px">
              <el-form-item label="提交时间">
                {{ formatDate1(item.submission_time) }}
              </el-form-item>
              <el-form-item label="审批状态">
                <span v-if="item.status==1">通过</span>
                <span v-else>不通过</span>
              </el-form-item>
              <el-form-item label="审批时间">
                {{ formatDate1(item.updated_at) }}
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="24">
          <div
              style="border-bottom: 1px solid #b1c6e0; margin:  20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">

          </div>
        </el-col>
      </el-row>


    </div>
    <div v-else>
      <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="160px"
          style="width: 90%">

        <el-row>

          <el-col :span="11">
            <el-form-item label="发起部门" prop="">
              {{ ruleForm.department_name }}
            </el-form-item>
          </el-col>


          <el-col :span="11">
            <el-form-item label="发起人" prop="">
              {{ user_name }}
            </el-form-item>
          </el-col>

          <el-col :span="11">
            <el-form-item label="是否垫资" prop="">
              <!--            <span v-if="ruleForm.is_advance_money==1">是</span>-->
              <!--            <span v-else>否</span>-->
              {{ruleForm.is_advance_money=="0"?"否":ruleForm.is_advance_money=="1"?"是":""}}
              <div style="color: red" v-if="ruleForm.is_advance_money !== old_data.is_advance_money">变更前：{{old_data.is_advance_money=="0"?"否":old_data.is_advance_money=="1"?"是":""}}</div>
            </el-form-item>
          </el-col>


          <el-col :span="11">
            <el-form-item label="交易负责人" prop="transaction_loser">
              <!--            <el-input disabled v-model="ruleForm.transaction_loser" placeholder="请输入交易负责人"></el-input>-->
              {{ruleForm.transaction_loser}}
              <div style="color: red" v-if="ruleForm.transaction_loser !== old_data.transaction_loser">变更前：{{old_data.transaction_loser}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div
                style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">
              供应商信息
            </div>
          </el-col>

          <el-col :span="11">
            <el-form-item label="供应商" prop="">
              <!--            <el-select disabled v-model="ruleForm.supplier" filterable clearable placeholder="请选择">-->
              <!--              <el-option-->
              <!--                  v-for="(item,key) in supplier_optionsMetaShow"-->
              <!--                  :key="key"-->
              <!--                  :label="item.companyname"-->
              <!--                  :value="item.companyname">-->
              <!--              </el-option>-->
              <!--            </el-select>-->
              {{ruleForm.supplier}}
              <div style="color: red" v-if="ruleForm.supplier !== old_data.supplier">变更前：{{old_data.supplier}}</div>
            </el-form-item>
          </el-col>

          <el-col :span="11">
            <el-form-item label="供应商经办人" prop="">
              <!--            <el-input disabled v-model="ruleForm.supplier_operator" placeholder="供应商经办人"></el-input>-->
              {{ruleForm.supplier_operator}}
              <div style="color: red" v-if="ruleForm.supplier_operator !== old_data.supplier_operator">变更前：{{old_data.supplier_operator}}</div>
            </el-form-item>
          </el-col>

          <el-col :span="11">
            <el-form-item label="供应商联系电话" prop="supplier_num">
              <!--            <el-input disabled v-model="ruleForm.supplier_num" placeholder="供应商联系电话"></el-input>-->
              {{ruleForm.supplier_num}}
              <div style="color: red" v-if="ruleForm.supplier_num !== old_data.supplier_num">变更前：{{old_data.supplier_num}}</div>
            </el-form-item>
          </el-col>


          <el-col :span="11">
            <el-form-item label="供货提货地点" prop="project_title">
              <!--            <el-cascader disabled size="large" :options="options" v-model="form.selectedOptions1"-->
              <!--                         @change="handleChange1" clearable style="width:100%">-->
              <!--            </el-cascader>-->
              {{ruleForm.supplier_province}}/{{ruleForm.supplier_city}}/{{ruleForm.supplier_area}}
              <div style="color: red" v-if="ruleForm.supplier_province !== old_data.supplier_province || ruleForm.supplier_city !== old_data.supplier_city ||ruleForm.supplier_area !== old_data.supplier_area">变更前：{{old_data.supplier_province}}/{{old_data.supplier_city}}/{{old_data.supplier_area}}</div>
            </el-form-item>
            <el-form-item label="详细地址" prop="project_title">
              <!--            <el-input disabled v-model="ruleForm.supplier_address" placeholder="详细地址"></el-input>-->
              {{ruleForm.supplier_address}}
              <div style="color: red" v-if="ruleForm.supplier_address !== old_data.supplier_address">变更前：{{old_data.supplier_address}}</div>
            </el-form-item>

          </el-col>


          <el-col :span="11">
            <el-form-item label="供货提货时间">
              <!--            <el-form-item prop="supplier_picking_time">-->
              <!--              <el-date-picker disabled type="date" placeholder="请选择日期" v-model="ruleForm.supplier_picking_time"-->
              <!--                              value-format="timestamp"-->
              <!--                              @change="getCycleTime('end_time')"-->
              <!--                              style="width: 100%;"></el-date-picker>-->
              <!--            </el-form-item>-->
              {{Utils.timeDate(ruleForm.supplier_picking_time)}}
              <div style="color: red" v-if="ruleForm.supplier_picking_time !== old_data.supplier_picking_time">变更前：{{Utils.timeDate(old_data.supplier_picking_time)}}</div>
            </el-form-item>
          </el-col>


          <el-col :span="11">
            <el-form-item label="供货付款方式" prop="">
              <!--            <el-select disabled v-model="ruleForm.supplier_payment_method" clearable placeholder="请选择">-->
              <!--              <el-option-->
              <!--                  v-for="(item,key) in tableProp.supplier_payment_methodProp"-->
              <!--                  :key="key"-->
              <!--                  :label="item.label"-->
              <!--                  :value="item.value">-->
              <!--              </el-option>-->
              <!--            </el-select>-->
              {{ruleForm.supplier_payment_method}}
              <div style="color: red" v-if="ruleForm.supplier_payment_method !== old_data.supplier_payment_method">变更前：{{old_data.supplier_payment_method}}</div>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <div
                style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">
              采购商信息
            </div>
          </el-col>

          <el-col :span="11">
            <el-form-item label="采购商" prop="">
              <!--            <el-select disabled v-model="ruleForm.purchaser" filterable clearable placeholder="请选择">-->
              <!--              <el-option-->
              <!--                  v-for="(item,key) in purchaser_optionsMetaShow"-->
              <!--                  :key="key"-->
              <!--                  :label="item.companyname"-->
              <!--                  :value="item.companyname">-->
              <!--              </el-option>-->
              <!--            </el-select>-->
              {{ruleForm.purchaser}}
              <div style="color: red" v-if="ruleForm.purchaser !== old_data.purchaser">变更前：{{old_data.purchaser}}</div>
            </el-form-item>
          </el-col>

          <el-col :span="11">
            <el-form-item label="采购商经办人" prop="">
              <!--            <el-input disabled v-model="ruleForm.purchaser_operator" placeholder="供应商经办人"></el-input>-->
              {{ruleForm.purchaser_operator}}
              <div style="color: red" v-if="ruleForm.purchaser_operator !== old_data.purchaser_operator">变更前：{{old_data.purchaser_operator}}</div>
            </el-form-item>
          </el-col>

          <el-col :span="11">
            <el-form-item label="采购商联系电话" prop="purchaser_num">
              <!--            <el-input disabled v-model="ruleForm.purchaser_num" placeholder="采购商联系电话"></el-input>-->
              {{ruleForm.purchaser_num}}
              <div style="color: red" v-if="ruleForm.purchaser_num !== old_data.purchaser_num">变更前：{{old_data.purchaser_num}}</div>
            </el-form-item>
          </el-col>


          <el-col :span="11">
            <el-form-item label="采购提货地点" prop="project_title">
              <!--            <el-cascader disabled :options="options" v-model="form.selectedOptions2"-->
              <!--                         @change="handleChange2" clearable style="width:100%">-->
              <!--            </el-cascader>-->
              {{ruleForm.purchaser_province}}/{{ruleForm.purchaser_city}}/{{ruleForm.purchaser_area}}
              <div style="color: red" v-if="ruleForm.purchaser_province !== old_data.purchaser_province || ruleForm.purchaser_city !== old_data.purchaser_city ||ruleForm.purchaser_area !== old_data.purchaser_area">变更前：{{old_data.purchaser_province}}/{{old_data.purchaser_city}}/{{old_data.purchaser_area}}</div>

              <!--          <el-cascader  v-model="selectedOptions"   :options="options" :props="defaultParams" @change="handleChange" style="width: 140px; overflow: hidden"></el-cascader>-->

              <!--          <el-input v-model="ruleForm.project_title" placeholder="采购提货地点"></el-input>-->
            </el-form-item>
            <el-form-item label="详细地址" prop="project_title">
              <!--            <el-input disabled v-model="ruleForm.purchaser_address" placeholder="详细地址"></el-input>-->
              {{ruleForm.purchaser_address}}
              <div style="color: red" v-if="ruleForm.purchaser_address !== old_data.purchaser_address">变更前：{{old_data.purchaser_address}}</div>
            </el-form-item>

          </el-col>


          <el-col :span="11">
            <el-form-item label="采购提货时间">
              <!--            <el-form-item prop="end_time">-->
              <!--              <el-date-picker disabled type="date" placeholder="请选择日期" v-model="ruleForm.purchaser_picking_time"-->
              <!--                              value-format="timestamp"-->
              <!--                              @change="getCycleTime('end_time')"-->
              <!--                              style="width: 100%;"></el-date-picker>-->
              <!--            </el-form-item>-->
              {{Utils.timeDate(ruleForm.purchaser_picking_time)}}
              <div style="color: red" v-if="ruleForm.purchaser_picking_time !== old_data.purchaser_picking_time">变更前：{{Utils.timeDate(old_data.purchaser_picking_time)}}</div>
            </el-form-item>
          </el-col>


          <el-col :span="11">
            <el-form-item label="采购付款方式" prop="">
              <!--            <el-select disabled v-model="ruleForm.purchaser_payment_method" clearable placeholder="请选择">-->
              <!--              <el-option-->
              <!--                  v-for="(item,key) in tableProp.purchaser_payment_methodProp"-->
              <!--                  :key="key"-->
              <!--                  :label="item.label"-->
              <!--                  :value="item.value">-->
              <!--              </el-option>-->
              <!--            </el-select>-->
              {{ruleForm.purchaser_payment_method}}
              <div style="color: red" v-if="ruleForm.purchaser_payment_method !== old_data.purchaser_payment_method">变更前：{{old_data.purchaser_payment_method}}</div>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="是否新客户" prop="">
              <!--            <el-switch v-model="ruleForm.is_external"></el-switch>-->
              <!--            <el-radio-group disabled v-model="ruleForm.is_new_user">-->
              <!--              <el-radio label="0">否</el-radio>-->
              <!--              <el-radio label="1">是</el-radio>-->
              <!--            </el-radio-group>-->
              {{ruleForm.is_new_user=="0"?"否":ruleForm.is_new_user=="1"?"是":""}}
              <div style="color: red" v-if="ruleForm.is_new_user !== old_data.is_new_user">变更前：{{old_data.is_new_user=="0"?"否":old_data.is_new_user=="1"?"是":""}}</div>
            </el-form-item>
          </el-col>


          <el-col :span="24">
            <div
                style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">
              产品信息
            </div>
          </el-col>
          <common-table
              :tableData="ruleForm.business_product_json"
              :tableProp="tableProp"
          />

          <div>
            <el-col :span="24">
              <div
                  style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">
                成单信息
              </div>
            </el-col>

            <el-col :span="11">
              <el-form-item label="是否深度认证" prop="">
                <!--              <span v-if="ruleForm.is_depth==1">是</span>-->
                <!--              <span v-else>否</span>-->
                {{ruleForm.is_depth==0?"否":ruleForm.is_depth==1?"是":""}}
                <div style="color: red" v-if="ruleForm.is_depth !== old_data.is_depth">变更前：{{old_data.is_depth==0?"否":old_data.is_depth==1?"是":""}}</div>

              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="是否平台签合同" prop="">
                <!--            <el-switch v-model="ruleForm.is_external"></el-switch>-->
                <!--              <span v-if="ruleForm.is_sign_a_contract==1">是</span>-->
                <!--              <span v-else>否</span>-->
                {{ruleForm.is_sign_a_contract==0?"否":ruleForm.is_sign_a_contract==1?"是":""}}
                <div style="color: red" v-if="ruleForm.is_sign_a_contract !== old_data.is_sign_a_contract">变更前：{{old_data.is_sign_a_contract==0?"否":old_data.is_sign_a_contract==1?"是":""}}</div>
              </el-form-item>
            </el-col>


            <common-table
                :tableData="ruleForm.business_product_json"
                :tableProp="tableProp2"
            />
            <el-col :span="24">
              <div
                  style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;">
                附件信息
              </div>
            </el-col>

            <el-col :span="22">
              <el-form-item label="" prop="01">
                <common-upload-file-test :uploadFileData="ruleForm.attachment_json"/>
              </el-form-item>
            </el-col>
          </div>

          <el-col :span="22">
            <el-form-item style="margin-top: 20px;">
              <el-button type="primary" @click="tongguo(1)"  v-if="business_status == 0">通过</el-button>
              <el-button @click="tongguo(2)"  v-if="business_status == 0">不通过</el-button>
            </el-form-item>
          </el-col>
        </el-row>


      </el-form>
    </div>

    <el-dialog
        :append-to-body="true"
        :title="addDialogData.title"
        :visible.sync="addDialogData.isDialog"
        :top="addDialogData.top"
        :width="addDialogData.width"
    >
      <div>


        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-form ref="form" label-width="120px">
                <el-form-item label="">
                  {{ info }}
                </el-form-item>
                <el-form-item label="审核意见：">

                  <el-input v-model="fail_reasons" placeholder="审核意见"></el-input>

                </el-form-item>

                <el-form-item style="margin-top: 20px;">
                  <el-button type="default" @click="addDialogData.isDialog=false">取消</el-button>
                  <el-button type="primary" @click="submitData">提交</el-button>
                </el-form-item>

              </el-form>
            </div>
          </el-col>

        </el-row>


      </div>
    </el-dialog>

  </div>


</template>
<script>
import {timer} from '@/assets/js/comment';
import {regionData, CodeToText, TextToCode} from "element-china-area-data";
import {
  getDepartment,
  getPost,
  getProjectType,
  getUser
} from '@/api/project_management/new_project'
import http from "@/api/http";
import {businessCreate, businessDetail, businessEdit, audit} from "api/business/business_func";


export default {
  components: {},
  data() {
    return {
      pageInfo: "bussness",
      info: "",

      fail_reasons: "",

      options: regionData,
      form: {
        selectedOptions1: [],
        selectedOptions2: [],
        provinceCode1: "",
        cityCode1: "",
        areaCode1: "",
        provinceCode2: "",
        cityCode2: "",
        areaCode2: "",
      },
      //addDialog数据源
      addDialogData: {
        isDialog: false,//dialog是否显示
        subMethod: 'addDialogSub',//点击确定方法名
        title: '审核',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 显示数据
        addDialogShowData: {
          name: '',
          user_id: '',
          department_name: '',
          station_name: '',
          growth_value: '',
          my_all_grow_value: '',
          my_sum_grow_value: '',
        },
      },


      levelText: '',
      user_name: "",
      ruleForm: {
        is_advance_money: "1",
        transaction_loser: '',
        supplier: '',
        supplier_operator: '',
        supplier_num: '',
        supplier_picking_time: '',
        supplier_payment_method: '',
        supplier_province: '',
        supplier_city: '',
        supplier_area: '',
        supplier_address: '',


        purchaser: '',
        purchaser_operator: '',
        purchaser_num: '',
        purchaser_picking_time: '',
        purchaser_payment_method: '',
        purchaser_province: '',

        purchaser_city: '',
        purchaser_area: '',
        purchaser_address: '',
        is_new_user: "1",
        attachment_json: [],

        is_depth: 1,
        is_sign_a_contract: 1,

        business_product_json:
            [{
              "name": "",
              "is_new_products": 1,
              "num": 0,
              "purchase_price": 0,
              "shipment_price": 0,
              "is_tax_included": 1,
              "estimated_advance_amount": 0,
              "estimated_advance_date": "",
              "estimated_repayment_date": "",
              "logistics_type": "\u7a7a\u8fd0",
              "estimated_logistics_expenses": 0
            }],
        audit_json: {},
      },
      old_data:{},
      sc_member: [],
      type_idOption: [],
      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: false,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //tabs 标题
        tabsData: [
          {
            label: '商贸信息',
            page: 'bussness',
            name: '0',
          },
          {
            label: '审核信息',
            page: 'shenp',
            name: '1',
          },
        ]
      },

      tableProp2: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '产品名称',
          field: 'name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '是否新品',
          field: 'is_new_products',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '产品数量',
          field: 'num',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '进货单价 ',
          field: 'purchase_price',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },

          {
            title: '进货总价格(元)',
            field: 'purchase_all_price',
            isWidth: false,
            width: '140px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },

          {
            title: '产品单位',
            field: 'unit',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },


          {
            title: '出货单价 ',
            field: 'shipment_price',
            isWidth: false,
            width: '140px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '出货总价格(元)',
            field: 'shipment_all_price',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },


          {
            title: '差价(元)',
            field: 'price_difference',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },


          {
            title: '是否含税',
            field: 'is_tax_included',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },

          {
            title: '实际垫资金额(元)',
            field: 'actual_advance_amount',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },

          {
            title: '实际垫资日期',
            field: 'actual_advance_date',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },

          {
            title: '实际还款日期',
            field: 'actual_repayment_date',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },


          {
            title: ' 实际垫资总天数(天)',
            field: 'actual_total_advance_days',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },

          {
            title: '需使用物流类型',
            field: 'logistics_type',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '实际物流费(元)',
            field: 'actual_logistics_expenses',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '资金成本(元)',
            field: 'cost_of_capital',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '税费(元)',
            field: 'taxation',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '税后营收(元)',
            field: 'after_tax_revenue',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          }],

      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '产品名称',
          field: 'name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '是否新品',
          field: 'is_new_products',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '产品数量',
          field: 'num',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '进货单价 ',
          field: 'purchase_price',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },

          {
            title: '进货总价格(元)',
            field: 'purchase_all_price',
            isWidth: false,
            width: '140px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '产品单位',
            field: 'unit',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },


          {
            title: '出货单价 ',
            field: 'shipment_price',
            isWidth: false,
            width: '140px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '出货总价格(元)',
            field: 'shipment_all_price',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },


          {
            title: '差价(元)',
            field: 'price_difference',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },


          {
            title: '是否含税',
            field: 'is_tax_included',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },

          {
            title: '申请垫资金额(元)',
            field: 'estimated_advance_amount',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },

          {
            title: '预计垫资日期',
            field: 'estimated_advance_date',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },

          {
            title: '预计还款日期',
            field: 'estimated_repayment_date',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },


          {
            title: ' 预计垫资总天数(天)',
            field: 'estimated_total_advance_days',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },

          {
            title: '需使用物流类型',
            field: 'logistics_type',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '预估物流费(元)',
            field: 'estimated_logistics_expenses',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '资金成本(元)',
            field: 'cost_of_capital',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '税费(元)',
            field: 'taxation',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          },
          {
            title: '税后营收(元)',
            field: 'after_tax_revenue',
            isWidth: false,
            width: '120px',
            headerAlign: 'center',
            align: 'center',
            tooltip: false,
            type: 0,
          }],
        is_new_productsProp: [
          {value: 1, label: '是'},
          {value: 2, label: '否'}
        ],
        is_tax_includedProp: [
          {value: 1, label: '是'},
          {value: 2, label: '否'}
        ],

        logistics_typeProp: [
          {value: "陆运", label: '陆运'},
          {value: "水运", label: '水运'},
          {value: "空运", label: '空运'},
        ],

        supplier_payment_methodProp: [
          {value: "陆运", label: '陆运'},
          {value: "水运", label: '水运'},
          {value: "空运", label: '空运'},
        ],
        purchaser_payment_methodProp: [
          {value: "陆运", label: '陆运'},
          {value: "水运", label: '水运'},
          {value: "空运", label: '空运'},
        ],

        role_idProp: [
          {value: 1, label: '项目经理'},
          {value: 2, label: '市场'},
          {value: 3, label: '商务'},
          {value: 4, label: '行政'},
          {value: 5, label: '产品'},
          {value: 6, label: '其他'}
        ],
      },
      shenP: {
        labelWidth: "160px",
        shenPr: [],
        type: 1,
        titleType: 1,
        shenpm: '',
        data: [],
      },

      supplier_optionsMetaAll: [],
      supplier_optionsMetaShow: [],
      supplier_valueMeta: [],

      purchaser_optionsMetaAll: [],
      purchaser_optionsMetaShow: [],
      purchaser_valueMeta: [],


      business_end: [],
      status: 1,
      auditRecord: [],

      business_new: [],

      isshowChengdan:false,
      isHide:false,

      business_status:0,

    };
  },
  created() {
    //获取尚食云彩的供应商采购商
    // https://www.ssyuncai.com/api/pc/allUser?type=1
    http.get('https://www.ssyuncai.com/api/pc/allUser?type=1')
        .then((res) => {
          console.log(res)
          this.supplier_optionsMetaAll = res.data
          this.supplier_optionsMetaShow = res.data
        });

    http.get('https://www.ssyuncai.com/api/pc/allUser?type=2')
        .then((res) => {
          this.purchaser_optionsMetaAll = res.data
          this.purchaser_optionsMetaShow = res.data
        });

    // this.getProjectType()
    if (this.$route.query.id) {
      this.init()
    }
    this.business_status = this.$route.query.status
    // if (!this.$route.query.id) {
    //   this.ruleForm.business_product_json[0].department_name = Number(localStorage.getItem("departmentId"))
    //   this.getPost(this.ruleForm.business_product_json[0])
    //   this.ruleForm.business_product_json[0].post_name = Number(localStorage.getItem("postId"))
    //   this.getUser(this.ruleForm.business_product_json[0])
    //   this.ruleForm.business_product_json[0].user_id = Number(localStorage.getItem("userId"))
    // }
  },
  mounted() {
    this.$store.state.loading = false;
  },
  methods: {
    submitData() {
      let data = {
        id: this.$route.query.audit_id,
        status: this.status,
        fail_reasons: this.fail_reasons
      }
      audit(data).then(result => {
        this.$message({
          message: result.msg,
          type: 'success'
        });
        this.addDialogData.isDialog = false
        // this.$router.push({path: './business_list'})
        this.$router.go(-1)
      })
    },
    //
    tongguo(status) {
      this.fail_reasons = ""
      if (status == 1) {
        //通过
        this.info = "此操作为审核通过，是否继续？"
      } else {
        // 不通过
        this.info = "此操作为审核不通过，请填写不通过原因"

      }
      this.status = status
      this.addDialogData.isDialog = true
    },

    //tabs 切换点击
    tabsClick(tab) {

      this.pageInfo = this.tabsProp.tabsData[tab.name].page

    },


    handleChange1() {
      let loc = "";
      for (let i = 0; i < this.form.selectedOptions1.length; i++) {
        loc += CodeToText[this.form.selectedOptions1[i]];
      }
      // this.ruleForm.supplier_province = CodeToText[this.form.selectedOptions1[0]]+"|"+this.form.selectedOptions1[0];
      // this.ruleForm.supplier_city = CodeToText[this.form.selectedOptions1[1]]+"|"+this.form.selectedOptions1[1];
      // this.ruleForm.supplier_area = CodeToText[this.form.selectedOptions1[2]]+"|"+this.form.selectedOptions1[2];
      this.ruleForm.supplier_province = CodeToText[this.form.selectedOptions1[0]];
      this.ruleForm.supplier_city = CodeToText[this.form.selectedOptions1[1]];
      this.ruleForm.supplier_area = CodeToText[this.form.selectedOptions1[2]];
      console.log(loc)
      console.log(this.ruleForm)

    },

    handleChange2() {

      // this.ruleForm.purchaser_province = CodeToText[this.form.selectedOptions2[0]]+"|"+this.form.selectedOptions2[0];
      // this.ruleForm.purchaser_city = CodeToText[this.form.selectedOptions2[1]]+"|"+this.form.selectedOptions2[1];
      // this.ruleForm.purchaser_area = CodeToText[this.form.selectedOptions2[2]]+"|"+this.form.selectedOptions2[2];

      this.ruleForm.purchaser_province = CodeToText[this.form.selectedOptions2[0]];
      this.ruleForm.purchaser_city = CodeToText[this.form.selectedOptions2[1]];
      this.ruleForm.purchaser_area = CodeToText[this.form.selectedOptions2[2]];
    },


    init() {
      businessDetail({id: this.$route.query.id}).then((result) => {

        if (result.data.auditRecord != null) {
          this.auditRecord = result.data.auditRecord
        }

        this.user_name = result.data.user_info.name


        this.ruleForm = result.data.new
        this.old_data = result.data.old
        this.ruleForm.purchaser_picking_time *= 1000;
        this.ruleForm.supplier_picking_time *= 1000;


        this.ruleForm.attachment_json = JSON.parse(this.ruleForm.attachment_json)
        this.shenP.data = JSON.parse(this.ruleForm.audit_json)

        this.form.selectedOptions1 = [
          TextToCode[this.ruleForm.supplier_province].code,
          TextToCode[this.ruleForm.supplier_province][this.ruleForm.supplier_city].code,
          TextToCode[this.ruleForm.supplier_province][this.ruleForm.supplier_city][this.ruleForm.supplier_area].code
        ]
        this.form.selectedOptions2 = [
          TextToCode[this.ruleForm.purchaser_province].code,
          TextToCode[this.ruleForm.purchaser_province][this.ruleForm.purchaser_city].code,
          TextToCode[this.ruleForm.purchaser_province][this.ruleForm.purchaser_city][this.ruleForm.purchaser_area].code
        ]


        if (this.ruleForm.business_product) {
          this.ruleForm.business_product_json = this.ruleForm.business_product

          for (let i = 0; i < this.ruleForm.business_product_json.length; i++) {
            this.ruleForm.business_product_json[i].estimated_repayment_date = this.ruleForm.business_product_json[i].estimated_repayment_date?this.formatDate(this.ruleForm.business_product_json[i].estimated_repayment_date):''
            this.ruleForm.business_product_json[i].estimated_advance_date = this.ruleForm.business_product_json[i].estimated_advance_date?this.formatDate(this.ruleForm.business_product_json[i].estimated_advance_date):''

            this.ruleForm.business_product_json[i].actual_advance_date = this.ruleForm.business_product_json[i].actual_advance_date?this.formatDate(this.ruleForm.business_product_json[i].actual_advance_date/1000):''
            this.ruleForm.business_product_json[i].actual_repayment_date = this.ruleForm.business_product_json[i].actual_repayment_date?this.formatDate(this.ruleForm.business_product_json[i].actual_repayment_date/1000):''


            switch (this.ruleForm.business_product_json[i].is_new_products) {
              case 0:
                this.ruleForm.business_product_json[i].is_new_products = '';
                break;
              case 1:
                this.ruleForm.business_product_json[i].is_new_products = '是';
                break;
              case 2:
                this.ruleForm.business_product_json[i].is_new_products = '否';
                break;
            }
            switch (this.ruleForm.business_product_json[i].is_tax_included) {
              case 0:
                this.ruleForm.business_product_json[i].is_tax_included = '';
                break;
              case 1:
                this.ruleForm.business_product_json[i].is_tax_included = '是';
                break;
              case 2:
                this.ruleForm.business_product_json[i].is_tax_included = '否';
                break;
            }

            console.log(this.ruleForm.business_product_json[i].actual_advance_date)
            console.log(this.ruleForm.business_product_json[i].actual_repayment_date)

            if (result.data.new.business_product[i] &&
                result.data.old.business_product[i] &&
                parseFloat(result.data.new.business_product[i].shipment_price)!=parseFloat(result.data.old.business_product[i].shipment_price)){
              console.log(i)
              this.ruleForm.business_product_json[i].shipment_price = result.data.new.business_product[i].shipment_price + `\n`+
                  "(修改前："+result.data.old.business_product[i].shipment_price +")"
            }
          }
        }
        if (result.data.new.purchaser_payment_method !== result.data.old.purchaser_payment_method){
          this.purchaser_payment_method = result.data.old.purchaser_payment_method
        }
      })
    },
    //计算计划周期与交付时间
    getCycleTime(val) {
      if (this.ruleForm.end_time && this.ruleForm.start_time) {
        this.ruleForm.cycle_time = timer(this.ruleForm.end_time - this.ruleForm.start_time)
        this.ruleForm.business_product_json[0].start_time = this.ruleForm.start_time
        this.ruleForm.business_product_json[0].end_time = this.ruleForm.end_time
        if (this.ruleForm.end_time < this.ruleForm.start_time) {
          this.$confirm('结束时间必须大于开始时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (val == 'start_time') {
              this.ruleForm.start_time = ''
            }
            if (val == 'end_time') {
              this.ruleForm.end_time = ''
            }
          })
              .catch(() => {
                if (val == 'start_time') {
                  this.ruleForm.start_time = ''
                }
                if (val == 'end_time') {
                  this.ruleForm.end_time = ''
                }
              })
        }
      }
      if (val == 'start_time') {
        if (this.ruleForm.cycle_time && this.ruleForm.start_time) {
          this.ruleForm.end_time = this.ruleForm.start_time + Number(this.ruleForm.cycle_time) * 24 * 60 * 60 * 1000
        }
      }
    },
    setEndTime() {
      //判断输入是否为大于0的整数
      if (!Number(this.ruleForm.cycle_time) || Number(this.ruleForm.cycle_time) !== Number(Number(this.ruleForm.cycle_time).toFixed(0)) || Number(this.ruleForm.cycle_time) < 0) {
        this.$confirm('计划项目周期必须为大于0的整数！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.ruleForm.cycle_time = ""
        })
        return false
      }
      if (this.ruleForm.start_time) {
        this.ruleForm.end_time = this.ruleForm.start_time + Number(this.ruleForm.cycle_time) * 24 * 60 * 60 * 1000
        this.ruleForm.business_product_json[0].start_time = this.ruleForm.start_time
        this.ruleForm.business_product_json[0].end_time = this.ruleForm.end_time
      }
    },
    //提交表单
    submitForm() {

      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        let subData = JSON.parse(JSON.stringify(this.ruleForm))
        subData.supplier_picking_time = this.formatDate(subData.supplier_picking_time / 1000)
        subData.purchaser_picking_time = this.formatDate(subData.purchaser_picking_time / 1000)

        for (let i = 0; i < subData.business_product_json.length; i++) {
          subData.business_product_json[i].estimated_advance_date = this.formatDate(subData.business_product_json[i].estimated_advance_date / 1000)
          subData.business_product_json[i].estimated_repayment_date = this.formatDate(subData.business_product_json[i].estimated_repayment_date / 1000)
        }


        subData.business_product_json = JSON.stringify(subData.business_product_json)
        subData.attachment_json = JSON.stringify(subData.attachment_json)
        subData.audit_json = JSON.stringify(this.shenP.data)


        if (this.$route.query.id) {
          subData.id = this.$route.query.id
          businessEdit(subData).then((result) => {
            this.$message({
              message: result.msg,
              type: 'success'
            });
            this.$router.push({path: './business_list'})
          })
        } else {
          businessCreate(subData).then((result) => {
            this.$message({
              message: result.msg,
              type: 'success'
            });
            this.$router.push({path: './business_list'})
          })
        }
        // let subData = JSON.parse(JSON.stringify(this.ruleForm))
        // subData.start_time = subData.start_time / 1000;
        // subData.end_time = subData.end_time / 1000;
        // subData.attachment_json = JSON.stringify(subData.attachment_json);
        // subData.audit_json = JSON.stringify(this.shenP.data)
        // let sumJoin_rate = 0
        // for (let i = 0; i < subData.business_product_json.length; i++) {
        //   if (!subData.business_product_json[i].start_time || !subData.business_product_json[i].end_time || !subData.business_product_json[i].task_name) {
        //     this.$confirm('市场成员信息填写不完整！', '提示', {
        //       confirmButtonText: '确定',
        //       cancelButtonText: '取消',
        //       type: 'warning'
        //     }).then(() => {
        //     })
        //     return false
        //   }
        //   subData.business_product_json[i].start_time = subData.business_product_json[i].start_time / 1000
        //   subData.business_product_json[i].end_time = subData.business_product_json[i].end_time / 1000
        //   sumJoin_rate = sumJoin_rate + parseFloat(subData.business_product_json[i].join_rate)
        // }
        // if (sumJoin_rate !== 100) {
        //   this.$confirm('市场成员计划参与度加和必须等于一百！', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {
        //   })
        //   return false
        // }
        // if (!subData.dividend || !subData.hope_growth_value) {
        //   this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {
        //   })
        //   return false
        // }
        // if (subData.is_external == 0) {
        //   subData.external_cost = 0
        // }
        // let isRole = true
        // for (let i = 0; i < subData.business_product_json.length; i++) {
        //   switch (subData.business_product_json[i].role_id) {
        //     case 1:
        //       subData.business_product_json[i].role_name = '项目经理';
        //       subData.manager_id = subData.business_product_json[i].user_id;
        //       isRole = false
        //       break;
        //     case 2:
        //       subData.business_product_json[i].role_name = '市场';
        //       break;
        //     case 3:
        //       subData.business_product_json[i].role_name = '商务';
        //       break;
        //     case 4:
        //       subData.business_product_json[i].role_name = '行政';
        //       break;
        //     case 5:
        //       subData.business_product_json[i].role_name = '产品';
        //       break;
        //     case 6:
        //       subData.business_product_json[i].role_name = '其他';
        //       break;
        //   }
        // }
        // if (isRole) {
        //   this.$confirm('市场成员需要有项目经理！', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {
        //   })
        //   return false
        // }
        // subData.business_product_json = JSON.stringify(subData.business_product_json);
        // if (this.$route.query.id) {
        //   subData.id = this.$route.query.id
        //   editProject(subData).then((result) => {
        //     this.$message({
        //       message: result.msg,
        //       type: 'success'
        //     });
        //     this.$router.push({path: './project_list'})
        //   })
        // } else {
        //   addProject(subData).then((result) => {
        //     this.$message({
        //       message: result.msg,
        //       type: 'success'
        //     });
        //     this.$router.push({path: './project_list'})
        //   })
        // }
      })


    },

    dropDownSearch() {

      // supplier_optionsMetaAll: [],
      //     supplier_optionsMetaShow: [],
      //     supplier_valueMeta: [],

      var _this = this;
      _this.value_supplierMeta = [];
      _this.supplier_optionsMetaAll = _this.supplier_optionsMetaAll.filter(_this.filterSearch);
    },
    filterSearch(item) {
      return item.includes(this.ruleForm.supplier);
    },


    //重置表单
    resetForm() {
      this.ruleForm = this.$options.data().ruleForm
      this.shenP = this.$options.data().shenP
    },
    // 添加市场成员 按钮点击
    addMember() {

      let newMember = {
        name: '',
        is_new_products: 1,
        num: 0,
        purchase_price: 0,
        shipment_price: 0,
        is_tax_included: 1,
        estimated_advance_amount: 0,
        estimated_advance_date: "",
        estimated_repayment_date: "",
        logistics_type: "陆运",
        estimated_logistics_expenses: 0
      }
      newMember.start_time = this.ruleForm.start_time
      newMember.end_time = this.ruleForm.end_time
      this.ruleForm.business_product_json.push(newMember)
    },
    // 删除市场成员 按钮点击
    deleteMember(row, index) {
      this.ruleForm.business_product_json.splice(index, 1)
      this.sc_member.splice(index, 1)

      for (let i = 0; i < this.ruleForm.attachment_json.length; i++) {
        for (let a = 0; a < this.ruleForm.attachment_json[i].power_member.length; a++) {
          let aaa = this.ruleForm.attachment_json[i].power_member[a]
          if (aaa == row.user_id) {
            this.ruleForm.attachment_json[i].power_member.splice(a, 1)
          }
        }
      }
    },
    //市场成员起止时间
    start_timeChange(row) {
      /*if (row.start_time < new Date().getTime()-86400000 && row.start_time){
        this.$confirm('开始时间不得早于当前时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.start_time = ''
        })
            .catch(() => {
              row.start_time = ''
            })
      }*/
      if (row.start_time > this.ruleForm.end_time && row.start_time) {
        this.$confirm('开始时间不得晚于计划交付时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.start_time = ''
        })
            .catch(() => {
              row.start_time = ''
            })
      }
      if (row.end_time && row.start_time) {
        if (row.end_time < row.start_time) {
          this.$confirm('开始时间不得晚于结束时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            row.start_time = ''
          })
              .catch(() => {
                row.start_time = ''
              })
        }
      }
    },
    end_timeChange(row) {
      /*if (row.end_time < new Date().getTime()-86400000 && row.end_time){
        this.$confirm('结束时间不得早于当前时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.end_time = ''
        })
            .catch(() => {
              row.end_time = ''
            })
      }*/
      if (row.end_time > this.ruleForm.end_time && row.end_time) {
        this.$confirm('结束时间不得晚于项目计划交付时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.end_time = ''
        })
            .catch(() => {
              row.end_time = ''
            })
      }
      if (row.start_time && row.end_time) {
        if (row.end_time < row.start_time) {
          this.$confirm('结束时间不得早于开始时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            row.end_time = ''
          })
              .catch(() => {
                row.end_time = ''
              })
        }
      }
    },
    join_rateChange(row) {
      let reg = /^(0|[1-9]\d*)(\.\d{1,1})?/
      let res = row.join_rate.match(reg)
      if (res[0] !== res.input) {
        this.$message({
          message: '最多保留一位小数！',
          type: 'error'
        });
        row.join_rate = res[0]
      }
    },

    // addDialog 点击确定
    addDialogSub() {
      this.subData = {...this.addDialogData.addDialogShowData}  //ES6深拷贝
      this.subData.start_time = this.subData.start_time / 1000;
      this.subData.end_time = this.subData.end_time / 1000;
      this.subData.attachment_json = JSON.stringify(this.subData.attachment_json);

      // editProject(this.subData).then((result) => {
      //   this.addDialogData.isDialog = false;
      //   this.$message({
      //     message: result.msg,
      //     type: 'success'
      //   });
      // })
      //     .catch((error) => {
      //       this.$message({
      //         message: error.msg,
      //         type: 'error'
      //       });
      //     })
    },
    //获取部门
    getDepartment() {
      getDepartment().then((result) => {
        for (let i = 0; i < result.data.length; i++) {
          result.data[i].value = result.data[i].id
          result.data[i].label = result.data[i].department_name
        }
        this.tableProp.department_nameProp = result.data
      })
    },
    //获取岗位
    getPost(val) {
      val.post_name = ''
      val.user_id = ''
      val.post_nameProp = []
      val.user_idProp = []
      let params = {pid: val.department_name}
      getPost(params).then((result) => {
        for (let i = 0; i < result.data.length; i++) {
          result.data[i].value = result.data[i].id
          result.data[i].label = result.data[i].department_name
        }
        val.post_nameProp = result.data
      })
    },
    //获取人员
    getUser(val) {
      val.user_id = ''
      val.user_idProp = []
      let params = {department_id: val.post_name}
      getUser(params).then((result) => {
        for (let i = 0; i < result.data.length; i++) {
          result.data[i].value = result.data[i].user_id
          result.data[i].label = result.data[i].name
        }
        val.user_idProp = result.data
        if (!this.$route.query.id) {
          this.getUserName(this.ruleForm.business_product_json[0], 0)
        }
      })
    },
    //获取人员姓名
    getUserName(val, key) {
      let data = {
        user_id: val.user_id,
        user_name: ''
      }
      for (let i = 0; i < val.user_idProp.length; i++) {
        if (val.user_idProp[i].user_id == val.user_id) {
          data.user_name = val.user_idProp[i].name
        }
      }
      this.sc_member.splice(key, 1, data)
      // this.member.push(data)
    },
    //获取项目类型
    getProjectType() {
      getProjectType().then((result) => {
        this.type_idOption = result.data
      })
    },
    //  设置保密文本
    setText() {
      if (this.ruleForm.security_level == 1) {
        this.levelText = '保密要求：一般保密，不得透露给公司以外人员任何项目信息；妥善保管相关文件资料，项目完成后全部归档处理。'
      } else if (this.ruleForm.security_level == 2) {
        this.levelText = '保密要求：重要保密，不得透露给公司以外人员；不得带出公司；同事之间不得相互交流；只能向指定项目人员发送；除项目需要不得将存有相关资料的U盘等存储设备带离公司；妥善保管相关文件资料，项目完成后全部归档处理。'
      } else if (this.ruleForm.security_level == 3) {
        this.levelText = '保密要求：重点保密，不得透露给项目以外人员；项目周期以外资料须销毁；不得带出公司；同事之间不得相互交流；只能向指定项目人员发送；未经允许不得拷贝到U盘，发送到微信、邮件等公共网络上。'
      }
    },


    formatDate(item_date) {
      let date = new Date(parseInt(item_date) * 1000);
      let YY = date.getFullYear() + "-";
      let MM =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      // let hh =
      //     (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      // let mm =
      //     (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      //     ":";
      // let ss =
      //     date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD;
    },

    formatDate1(item_date) {
      let date = new Date(parseInt(item_date) * 1000);
      let YY = date.getFullYear() + "-";
      let MM =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hh =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let mm =
          (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
          ":";
      let ss =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " "+hh+mm+ss;
    },
    //项目角色选择判断项目经理是否唯一
    checkRole(row) {
      let num = 0
      for (let i = 0; i < this.ruleForm.business_product_json.length; i++) {
        if (this.ruleForm.business_product_json[i].role_id == 1) {
          num = num + 1
        }
      }
      if (num > 1) {
        this.$confirm('项目经理只能有一个！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.role_id = ''
        })
      }
    },
  },

}
</script>
<style>
.el-form > .el-form-item {
  width: 90%;
}
</style>
