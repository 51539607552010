<template>
  <div>
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>技术部项目结题</span>
      </div>

    </div>
    <!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @detileApproval="detileApproval"
        @passOrder="passOrder"
    />

    <el-dialog
        title="项目结题"
        :visible.sync="dialogFormVisible"
        :append-to-body="true"
    >
      <el-form :model="form">

        <el-form-item label="" :label-width="formLabelWidth">
          <el-radio-group v-model="form.status">
            <el-radio label="1">通过</el-radio>
            <el-radio label="2">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="form.status=='1'">
          <el-form-item label="技术部分红比例(%)" :label-width="formLabelWidth" class="required">
            <el-input v-model="form.consent_information_json.real_red_rate"></el-input>
          </el-form-item>
          <el-form-item label="技术部总成长值" :label-width="formLabelWidth" class="required">
            <el-input v-model="form.consent_information_json.real_grow_value"></el-input>
          </el-form-item>
          <el-form-item label="奖惩说明" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="form.consent_information_json.publish_reward"></el-input>
          </el-form-item>
          <el-form-item label="审批意见" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="form.consent_information_json.fail_reasons"></el-input>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label="审批意见" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="form.consent_information_json.fail_reasons"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogSub">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>
<script>
import {auditStep1, projectConclusionPage} from "../../../api/module/project/project_func";
import {getAuditPerData} from "../../../api/module/approval/common";
export default {
  components: {},
  props: [],
  data() {
    return {
      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: false,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        // 状态 下拉的值
        statusOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],
        // 项目类型 下拉的值
        type_idOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],
        // 商务负责人 下拉的值
        nameOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],

        //高级搜索的字段
        heighKewWordField: {status: '', project_title: '', type_id: '', name: '', customer: '',},
        //tabs 标题
        tabsData: [{
          label: '待审核',
          name: '0',
          total: 0,
        },{
          label: '已通过',
          name: '1',
          total: 0,
        },{
          label: '未通过',
          name: '2',
          total: 0,
        }, {
          label: '重新审核',
          name: '3',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '项目编号',
          field: 'project_sn',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目名称',
          field: 'project_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目类型',
          field: 'type_id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '提交部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '提交人',
          field: 'user_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目金额',
          field: 'all_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '计划成单时间',
          field: 'start_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '计划交付时间',
          field: 'end_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '操作',
          isWidth: false,
          width: '220px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 20,
          tableOperate: [{
            name: '项目详情',
            type: 'primary',
            method: 'detileApproval',
            status: '20000',
            key:'detail',
            id: 20000,
          }, {
            name: '审批',
            type: 'danger',
            method: 'passOrder',
            key:'check',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        consent_information_json: {
          real_red_rate:'',
          real_grow_value:'',
          publish_reward:'',
          fail_reasons: '',
        },
        id: 0,
        status: "1"
      },
      formLabelWidth:'150px',
      //表格数据
      tableData: [],
      //addDialog提交数据
      subData: {}
    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {

    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        status:this.tabsProp.active,
        type:2
      }
      projectConclusionPage(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;

        this.tableData = res.data
        for (let i = 0; i < this.tableData.length; i++) {
          //揭榜部门
          // if(this.tableData[i].user_station!=null){
          //   this.tableData[i].department_name = this.tableData[i].user_station.department_name
          // }else {
          //   this.tableData[i].department_name = '暂未结题'
          // }
          if(this.tableData[i].audit_info!=null){
            this.tableData[i].department_name = this.tableData[i].audit_info.submit_department_name
            this.tableData[i].user_name = this.tableData[i].audit_info.submit_user_name
          }else {
            this.tableData[i].department_name = '暂未记录'
            this.tableData[i].user_name = '暂未记录'
          }

          this.tableData[i].type_id = this.tableData[i].project_type.type_name
          // this.tableData[i].user_name = this.tableData[i].user.name
          this.tableData[i].all_price=(this.tableData[i].all_price/10000).toFixed(2)+ "万"

        }
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(tab)
    },

    //table 详情 按钮点击
    detileApproval(row, index) {
      console.log(row)
      console.log(index)
      this.$router.push({path:'/project_management/project_detail', query:{id:row.id}})
    },
    //table 确认成单 按钮点击
    passOrder(row) {
      console.log(row)
      this.dialogFormVisible  = true;
      this.form.id = row.audit_info.id

      let data = {
        table_name:'project_dc',
        ident_id:row.id,
      }
      getAuditPerData(data).then(res=>{
        console.log(res)
        // this.form.consent_information_json.market_grow_value = res.data.growth_rate
        // this.form.consent_information_json.market_red_rate = res.data.red_rate

        this.form.consent_information_json.real_grow_value =  res.data.growth_rate
        this.form.consent_information_json.real_red_rate = res.data.red_rate
        // this.form.consent_information_json.fail_reasons = row.audit_msg
        this.form.consent_information_json.fail_reasons = ''

        this.form.consent_information_json.boss_suggest = row.boss_suggest
        this.form.consent_information_json.boss_rp = row.boss_rp
        console.log(row)
      })




    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
    // addDialog 点击确定
    addDialogSub() {
      let data = {...this.form}
      if (data.status == 1){
        if (!data.consent_information_json.real_red_rate || !data.consent_information_json.real_grow_value){
          this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
      }

      // data.consent_information_json.real_red_rate=data.consent_information_json.real_red_rate/100
      data.consent_information_json = JSON.stringify(data.consent_information_json)
      auditStep1(data).then(resul => {
        this.dialogFormVisible = false;
        this.$message({
          message: resul.msg,
          type: 'success'
        });
        this.initLoad()

      })

    },


  },
};
</script>
<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>
