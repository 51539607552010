import http from '../http';

export function businessList (params) {
    return http({
        url: "/api/project/bo",
        method: "get",
        params:params
    })
}
export function businessSub (data) {
    return http({
        url: "/api/project/bo",
        method: "put",
        data:data
    })
}
export function businessDel (data) {
    return http({
        url: "/api/project/bo",
        method: "delete",
        data:data
    })
}
export function businessEdit (data) {
    return http({
        url: "/api/project/bo",
        method: "post",
        data:data
    })
}
export function businessOperation (data) {
    return http({
        url: "/api/project/bo_status",
        method: "post",
        data:data
    })
}
export function taskOperation (data) {
    return http({
        url: "/api/project/bo_task_status",
        method: "post",
        data:data
    })
}
